$site-nav-breakpoint: 62.5em;

.site-nav {
  border-bottom: $border-width solid $color-darken;
  box-shadow: 0 2px 8px 0 $color-darken-more;
  margin: auto auto (-$border-width);
  max-width: $container-max-width;
  position: sticky;
  top: 0;
  z-index: 2;

  @media screen and (min-width: $site-nav-breakpoint) {
    box-shadow: none;
  }

  &__logo {
    padding: $spacing-sm $spacing-sm;

    @media screen and (min-width: $site-nav-breakpoint) {
      padding: $spacing $spacing;
    }
  }

  &__top {
    align-items: center;
    background: $color-white;
    border-bottom: $border-width solid $border-color;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }

  &__burger {
    border-left: $border-width solid $border-color;

    @media screen and (min-width: $site-nav-breakpoint) {
      display: none;
    }
  }

  &__bottom {
    background: $color-white;
    border-bottom: $border-width solid $border-color;
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
    transition: transform $transition-duration $transition-timing;
    width: 100%;

    .site-nav--open & {
      transform: none;
    }

    @media screen and (min-width: $site-nav-breakpoint) {
      border-bottom: none;
      position: relative;
      transform: none;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: $spacing-sm 0 0;

    @media screen and (min-width: $site-nav-breakpoint) {
      display: flex;
      padding: 0;
    }
  }

  &__item {
    &--login {
      margin-top: $spacing-sm;
      position: relative;

      &::before {
        border-top: $border-width solid $border-color;
        content: '';
        left: $spacing;
        position: absolute;
        right: $spacing;
        top: 0;
      }

      .site-nav__link {
        padding: $spacing $spacing;
      }

      @media screen and (min-width: $site-nav-breakpoint) {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        flex-grow: 1;

        &::before {
          display: none;
        }
      }
    }
  }

  &__link {
    color: inherit;
    display: block;
    overflow: hidden;
    padding: $spacing-sm $spacing;
    position: relative;
    transition: background-color $transition-duration $transition-timing,
      color $transition-duration $transition-timing;

    &::before {
      background-color: $color-brand-primary;
      content: '';
      height: $line-height * 1em + $spacing-sm * 2;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(-100%, -50%);
      transition: transform $transition-duration $transition-timing;
      width: .25em;
    }

    &:hover,
    &:focus-visible {
      background-color: transparentize($color-brand-primary, 0.975);
      color: $link-color;
    }

    &:active {
      background-color: transparentize($color-brand-primary, 0.975);
    }

    &--active {
      &::before {
        transform: translate(0, -50%);
      }
    }

    @media screen and (min-width: $site-nav-breakpoint) {
      padding: $spacing $spacing;

      &::before {
        bottom: 0;
        height: .25em;
        top: auto;
        transform: translateY(100%);
        width: 100%;
      }

      &--active {
        &::before {
          transform: none;
        }
      }
    }
  }
}
