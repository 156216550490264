

.alert {
  color: $body-color;
  padding: $spacing $spacing-fluid;
  width: 100%;

  &__content {
    font-size: $font-size-sm;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &--fixed-padding {
    padding: $spacing $spacing;

    @media screen and (min-width: map-get($bp, desktop)) {
      padding: $spacing $spacing-lg;
    }
  }

  // Types

  &--info {
    background: $color-info-light;
  }

  &--warning {
    background: $color-warning-light;
  }

  &--success {
    background: $color-success-light;
  }

  &--danger {
    background: $color-danger-light;
  }
}
