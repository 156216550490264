

.title-group {
  align-items: center;
  display: flex;

  &__icon {
    color: inherit;
    font-size: 2.125em;
    line-height: 1;
    margin-right: $spacing-xs;
  }

  &__content {
    flex: 1 1 auto;
    min-width: 0;
  }

  &__eyebrow {
    color: inherit;
    font-size: $font-size-sm;
    margin: 0;
    padding: 0;
  }

  &__title {
    color: inherit;
    font-size: $h4-font-size;
    margin: 0;
    padding: 0;
  }

  // sizes
  &--extra-small {
    .title-group__title {
      font-weight: 400;
    }
  }

  &--small {
    font-size: $h3-font-size;
  }

  &--medium {
    font-size: $h2-font-size;
  }

  &--large {
    font-size: $h1-font-size;
  }

  // Themes

  &--black {
    color: $color-grey-darker;
  }

  &--white {
    color: $color-white;
  }

  &--brand-primary {
    color: $color-brand-primary;
  }

  &--blue-dark {
    color: $color-blue-dark;
  }

  &--green-dark {
    color: $color-green-dark;
  }

  &--green {
    color: $color-green;
  }

  &--orange-dark {
    color: $color-orange-dark;
  }
}
