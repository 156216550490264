.banner {
  &__image {
    aspect-ratio: 4 / 3;
    background-size: cover;
  }

  &__content {
    padding: $spacing $spacing;
  }

  &--space-hexagons,
  &--neon-down {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .banner__image {
      aspect-ratio: auto;
      height: 100%;
      width: 100%;
    }

    .banner__image,
    .banner__content {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }
  }

  &--one-to-one {
    .banner__image {
      background-color: $color-brand-grey-light;
      background-image: url('/images/banners/1-2-1.jpg');
      display: none;

      @media screen and (min-width: map-get($bp, lg)) {
        display: block;
      }
    }

    .banner__content {
      background: $color-brand-purple;
      color: $color-white;

      a {
        color: $color-white;
      }
    }
  }

  &--space-hexagons {
    min-height: 10rem;
    .banner__image {
      background-color: $color-brand-purple;
      background-image: url('/images/banners/cima-graphic-bg.jpg');
    }

    .banner__content {
      background: $color-darken-more-again;
      color: $color-white;
      margin-block: $spacing;
      text-align: center;
    }
  }

  &--smiling-person {
    .banner__image {
      background-color: $color-brand-primary-blue;
      background-image: url('/images/banners/smiling-lady.jpg');
      background-size: cover;
      display: none;

      @media screen and (min-width: map-get($bp, lg)) {
        display: block;
      }
    }

    .banner__content {
      background: $color-grey-lightest;
      color: $body-color;
    }
  }

  &--neon-down {
    background-color: #2a0240;
    overflow: hidden;
    position: relative;

    .banner__image {
      aspect-ratio: 300/157;
      background-image: linear-gradient(to right, #2a0240, transparent), url('/images/banners/neon-downward-arrow-purple-bg.jpg');
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }

    .banner__content {
      color: $color-white;
      padding-right: $spacing-xl*2.25;
      position: relative;
      z-index: 1;
    }
  }

  &--podcast-promo {
    background-color: $color-grey-lightest;
    text-overflow: ellipsis;
    position: relative;

    .banner__image {
      aspect-ratio: 320/170;
      background-image: url('/images/banners/podcast-promo.webp');

    }
  }
}
