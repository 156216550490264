


$transition-checkbox: transform $transition-duration $transition-timing, background $transition-duration $transition-timing;

.filter-checkbox {
  margin-bottom: $spacing;

  &__label {
    font-size: 1em;
    font-weight: 400;
    margin-bottom: $spacing-sm;
  }

  &__input {
    @include sr-only;
  }

  &__checkbox {
    background: $color-white;
    border: $border-width * 2 $border-style $color-green-dark;
    box-shadow: 0 0 0 0 $color-green-dark;
    display: inline-block;
    height: 1em;
    margin-left: $spacing-xs;
    text-align: center;
    transition: $transition-checkbox;
    vertical-align: -.375em;
    width: 1em;

    &:hover,
    .check:focus & {
      border-color: $color-green;
      transform: scale(1.1);
    }

    &:active {
      transform: scale(.95);
    }
  }

  &__icon {
    color: $color-white;
    left: -.125em;
    line-height: 1.25;
    margin: 0;
    position: relative;
    top: -.25em;
    transform: scale(0);
    transition: transform $transition-duration-boing $transition-timing-boing;
    vertical-align: baseline;
  }

  input:checked {
    + .filter-checkbox__checkbox {
      background: $color-green-dark;
      border-color: $color-green-dark;
      box-shadow: 0 0 1em 1em fade-out($color-green-dark, 1);
      transform: scale(1);
      transition: $transition-checkbox, box-shadow $transition-duration $transition-timing;

      &:hover,
      .filter-checkbox:focus & {
        transform: scale(1);
      }

      .filter-checkbox__icon {
        transform: scale(.75);
      }
    }
  }
}
