

.shop-link {
  align-items: center;
  border: $border-width solid $border-color;
  border-width: $border-width 0;
  color: $color-grey-darker;
  display: flex;
  justify-content: space-between;
  padding: $spacing 0;

  @media screen and (min-width: map-get($bp, tablet)) {
    border: $border-width solid $border-color;
    padding: $spacing $spacing;

    &:hover,
    &:focus {
      color: $link-hover-color;
    }

    &:active {
      background-color: $color-darken;
      color: $link-active-color;
    }
  }

  &__content {
    margin-right: $spacing-sm;
  }

  &__action {
    color: $link-color;
    font-size: $font-size-sm;
    font-weight: 700;
    text-align: right;
    text-decoration: none;

    .icon {
      color: $color-grey;
      margin-left: $spacing-sm;
    }
  }

  &__text {
    display: none;
  }

  @media screen and (min-width: map-get($bp, tablet)) {

    &__action {
      min-width: 7em;
    }

    &__text {
      display: inline;
      opacity: 0;
      transition: opacity $transition-duration $transition-timing;
    }

    &:hover &__text,
    &:focus &__text {
      opacity: 1;
    }
  }

  + .shop-link {
    border-top: 0 none;
  }
}
