.collapse-providers {
  background: $color-white;
  border: 1px solid $color-grey-lightest;
  cursor: pointer;
  padding: $spacing $spacing;
  transition: background-color $transition-duration $transition-timing;

  &:hover {
    background: $color-grey-more-lightest;
  }

  &__toggle {
    align-items: center;
    appearance: none;
    background: none;
    border: 0 none;
    border-radius: $border-radius;
    color: $color-grey;
    cursor: pointer;
    display: flex;
    font-size: $font-size-xl;
    height: 2.5rem;
    justify-content: center;
    padding: 0;
    transition: background-color $transition-duration $transition-timing;
    width: 2.5rem;
  }

  &--open {
    background: $color-grey-more-lightest;
  }
}
