

.home {

  &__hero {
    background-color: $color-brand-purple;
    background-image: url('/images/pages/home/home-hero.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 50%;
    min-height: 10em;
    position: relative;
    transform: translate(-50%, 0);
    width: 100vw;
    z-index: 0;

    &-inner {
      margin: 0 auto;
      max-width: $container-max-width;

      @media screen and (min-width: map-get($bp, desktop)) {
        margin-bottom: -$spacing-lg;
      }
    }

    &-content {
      padding: $spacing-fluid $spacing-fluid;

      @media screen and (min-width: map-get($bp, desktop)) {
        padding: $spacing-fluid-lg $spacing-fluid
      }

      &-context {
        color: $color-white;
        font-size: $font-size-lg;
        font-weight: $heading-font-weight;
      }
    }

    .lead {
      color: $color-white;

      @media screen and (min-width: map-get($bp, desktop)) {
        font-size: 1.5em;
        font-weight: 300;
      }
    }
  }

  &__actions {
    margin: $spacing 0;

    @media screen and (min-width: map-get($bp, sm)) {
      margin: $spacing-fluid 0;

      .button {
        margin-right: $spacing;
        min-width: 10em;
      }
    }
  }

  &__section {
    background: $color-white;
    margin: 0;
    min-height: auto;
    position: relative;
    z-index: 1;

    @media screen and (min-width: map-get($bp, lg)) {
      margin-bottom: $spacing-fluid-sm;
    }

    &-inner {
      padding: $spacing-fluid $spacing-fluid;

      @media screen and (min-width: map-get($bp, lg)) {
        padding: $spacing-fluid-sm $spacing-fluid;
      }

      @media screen and (min-width: map-get($bp, lg)) {
        &__intro-content {
          overflow: hidden;
        }
      }
    }

    .outset-image {
      height: auto;
      width: 100%;

      &--right {
        bottom: -$spacing-fluid;
        display: block;
        position: relative;
        right: -$spacing-fluid-sm;

        @media screen and (min-width: map-get($bp, lg)) {
          bottom: -$spacing-fluid-lg * 2;
          // margin-top: -$spacing-fluid;
          // width: 50%;
        }

        @media screen and (min-width: map-get($bp, xl)) {
          bottom: -$spacing-fluid;
        }

        @media screen and (min-width: map-get($bp, xxl)) {
          bottom: -$spacing-fluid-sm;
        }
      }
    }
  }

  .splash-box {
    overflow: hidden;

    &__image {
      display: block;
      height: auto;
      width: 100%;
    }

    &__text-box {
      color: $color-white;
      padding: $spacing-fluid $spacing-fluid;
      z-index: 2;

      @media screen and (min-width: map-get($bp, lg)) {
        padding: $spacing-fluid-sm $spacing-fluid;
      }

      &--pink {
        background: $color-brand-primary;
      }

      &--green {
        background: $color-green-dark;
      }

      &--blue {
        background: $color-blue-dark;
      }

      a {
        color: $color-white;
        font-weight: bold;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media screen and (min-width: map-get($bp, lg)) {
      position: relative;

      &__text-box {
        left: 0;
        position: absolute;
        right: 50%;
        top: $spacing-fluid;
      }

      &__text-box--right {
        left: 50%;
        right: 0;
      }
    }

  }
}
