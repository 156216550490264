

.article-meta {
  background: $color-grey-lightest;
  font-size: $font-size-sm;
  padding: $spacing $spacing;

  p:last-child {
    margin: 0;
  }
}
