


.tabs {
  width: 100%;

  &__header {
    background: $color-white;
    border-bottom: $border;
    overflow: auto;
    position: relative;

    .tabs--white & {
      background: $color-white;
    }

    .tabs--grey & {
      background: $color-grey-lightest;
    }

    /* width */
    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  &__list {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  &__item {
    display: inline-block;
    flex-grow: 1;
    white-space: nowrap;

    &--highlight {
      background: $color-grey-lighter;
      margin-left: auto;
    }
  }

  &__link {
    border-bottom: $spacing * .25 $border-style transparent;
    color: $color-grey-darker;
    display: block;
    font-size: $font-size-sm;
    padding: $spacing * .75 $spacing $spacing-sm;

    text-decoration: none;

    &:hover {
      color: $color-brand-primary;
      text-decoration: none;
    }

    .tabs__item--active & {
      color: $color-grey-darker;
      font-weight: bold;

      small {
        font-weight: normal;
      }
    }
  }

  &__selected {
    background: $color-brand-primary;
    bottom: 0;
    display: block;
    height: $spacing * .25;
    left: 0;
    position: absolute;
    transform: translateZ(0);
    transition-duration: $transition-duration;
    transition-property: width, left;
    transition-timing-function: $transition-timing;
    width: 1em;
  }

  &__tab-wrap {
    display: none;

    &--active {
      display: block;
    }

    // Back to front, as this is the tab content
    .tabs--white & {
      background: $color-grey-lightest;
    }

    .tabs--grey & {
      background: $color-white;
    }
  }

  &__tab {
    @include clearfix;
    width: 100%;

    &--padded {
      padding: $spacing-fluid-sm $spacing-fluid $spacing-fluid;
    }

    &--flex {
      display: flex;
    }
  }

  &--flex {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .tabs__header {
      flex-shrink: 1;
    }

    .tabs__content {
      display: flex;
      flex-grow: 1;
      overflow: auto;
      width: 100%; // Fix width shrinking in IE
    }

    .tabs__tab-wrap--active {
      display: flex;
      width: 100%;
    }
  }

  &--shrink-headers {
    .tabs__item {
      flex-grow: 0;
    }
  }
}
