

.control-slider {
  display: flex;
  justify-content: center;
  padding: $spacing-sm 0;

  &__label {
    flex-shrink: 1;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding-right: $spacing-sm;

    &--right {
      min-width: 4ch;
      padding: 0 0 0 $spacing-sm;

      @media screen and (min-width: map-get($bp, lg)) {
        min-width: 7ch;
      }
    }

    &-text {
      &--lg {
        display: none;
        min-width: 10ch;
      }

      &--sm {
        display: inline-block;
        min-width: 2ch;
      }

      @media screen and (min-width: map-get($bp, lg)) {
        &--lg {
          display: inline-block;
        }

        &--sm {
          display: none;
        }
      }
    }
  }

  &__units {
    display: none;
    flex-shrink: 1;
    line-height: 1;
    margin: 0;

    @media screen and (min-width: map-get($bp, lg)) {
      display: inline;
    }
  }

  &__wrap {
    flex-grow: 1;
  }

  &__slider {
    cursor: pointer;
    height: $spacing;

    &::after {
      background-image: repeating-linear-gradient(to right, transparent, transparent 5.75%, $color-white 5.76%, $color-white 6.24%, transparent 6.25%);
      content: '';
      height: $spacing;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__bar {
    height: $spacing;
    top: 0;

    &-0 {
      background: $color-green-dark;
    }

    &-1 {
      background: $color-grey-light;
    }
  }

  &__handle {
    background: transparent;
    border: 0;
    height: $spacing;
    top: 50%;
    transform: translate(0, -50%);
    width: 0;

    &::after {
      background: $color-brand-primary;
      content: '';
      display: block;
      height: $spacing * 1.25;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: .5em;
    }
  }
}
