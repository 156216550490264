@mixin clearfix {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin sr-only {
  border: 0;
  clip: rect(0 0 0 0); // IE
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin container {
  margin: auto;
  max-width: $container-max-width;
  width: 100%;
}

@mixin loading {
  &::before {
    background: currentColor;
    content: '';
    display: block;
    height: 1em;
    margin: .25em .125em;
    opacity: .1;
  }
}

@mixin logo {
  background: url('../../../public/images/logo.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@mixin logo-light {
  background: url('../../../public/images/logo-light.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@mixin card-hover-transition {
  transform: scale(1);
  transition-duration: $transition-duration;
  transition-property: transform, box-shadow, border-color;
  transition-timing-function: $transition-timing;

  &:hover {
    box-shadow: 0 .125em .25em $color-darken;
    transform: scale(1.025);
  }
}

@mixin themeBackground {
  &--brand-primary {
    background-color: $color-brand-primary;
  }
  &--brand-grey {
    background-color: $color-brand-grey;
  }
  &--blue {
    background-color: $color-blue;
  }
  &--blue-dark {
    background-color: $color-blue-dark;
  }
  &--green {
    background-color: $color-green;
  }
  &--green-dark {
    background-color: $color-green-dark;
  }
  &--orange {
    background-color: $color-orange;
  }
  &--orange-dark {
    background-color: $color-orange-dark;
  }
  &--black {
    background-color: $color-brand-black;
  }

  &--gradient-purple,
  &--life-gradient-purple {
    background-image: $gradient-purple;

    &-diagonal {
      background-image: $gradient-purple-diagonal;
    }
  }

  &--gradient-blue,
  &--body-gradient-blue {
    background-image: $gradient-blue;

    &-diagonal {
      background-image: $gradient-blue-diagonal;
    }
  }

  &--gradient-orange {
    background-image: $gradient-orange;

    &-diagonal {
      background-image: $gradient-orange-diagonal;
    }
  }

  &--gradient-green,
  &--mind-gradient-green {
    background-image: $gradient-green;

    &-diagonal {
      background-image: $gradient-green-diagonal;
    }
  }

  &--gradient-black {
    background-image: $gradient-black;

    &-diagonal {
      background-image: $gradient-black-diagonal;
    }
  }
}

@mixin themeColor {
  &--brand-primary {
    color: $color-brand-primary;
  }
  &--brand-grey {
    color: $color-brand-grey;
  }
  &--blue {
    color: $color-blue;
  }
  &--blue-dark {
    color: $color-blue-dark;
  }
  &--green {
    color: $color-green;
  }
  &--green-dark {
    color: $color-green-dark;
  }
  &--orange {
    color: $color-orange;
  }
  &--orange-dark {
    color: $color-orange-dark;
  }
  &--black {
    color: $color-brand-black;
  }
}

