

.syllabus-code {
  align-items: baseline;
  display: flex;

  &__code,
  &__letter,
  &__number {
    margin-right: $spacing-sm;
  }

  &__window {
    line-height: 0;
    font-size: $font-size-lg * 2;
    color: $color-orange-dark;
  }

  &--extra-small,
  &--small {
    .syllabus-code__code,
    .syllabus-code__letter,
    .syllabus-code__number {
      margin-right: $spacing-xs;
    }
  }

  &--window {
    align-items: center;
  }
}
