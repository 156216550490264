$webinar-padding-vertical-ratio-small: 100 - ($spacing-fluid * 2 / 1%);
$webinar-padding-horizontal-ratio-small: 100 - ($spacing-fluid * 2 / 1%);

$webinar-hero-spacing-vertical-small: $spacing-fluid * 100/$webinar-padding-vertical-ratio-small;
$webinar-hero-spacing-horizontal-small: $spacing-fluid * 100/$webinar-padding-horizontal-ratio-small;

$webinar-hero-spacing-vertical-large: $webinar-hero-spacing-vertical-small * 1/.65;
$webinar-hero-spacing-horizontal-large: $webinar-hero-spacing-horizontal-small * 1/.65;


.webinar-hero {
  background: $color-grey url('/images/pages/webinars/webinar-background.png');
  background-size: cover;
  margin: -$webinar-hero-spacing-vertical-small (-$webinar-hero-spacing-horizontal-small) $webinar-hero-spacing-vertical-small;
  padding: $webinar-hero-spacing-vertical-small * 1.25 $webinar-hero-spacing-horizontal-small;

  @media only screen and (min-width: map-get($bp, desktop)) {
    margin: -$webinar-hero-spacing-vertical-large 0 $webinar-hero-spacing-vertical-large (-$webinar-hero-spacing-horizontal-large);
    padding: $webinar-hero-spacing-vertical-large * 1.25 $webinar-hero-spacing-horizontal-large;
  }

  &__icon {
    background: $color-grey;
    border-radius: 50%;
    color: $color-white;
    font-size: 2em;
    height: 2em;
    line-height: 1;
    margin-right: $spacing-sm;
    padding: .5em;
    width: 2em;

    .icon {
      bottom: .05em;
      position: relative;
    }

    @include themeBackground;
  }
}
