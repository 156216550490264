

.icon-badge-component {
  position: relative;
  display: inline;

  &__container {
    display: inline;
    line-height: 1;
  }

  &__badge {
    color: currentColor;
    margin: 0;
  }

  &__icon {
    color: $color-white;
    font-size: .5em;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--info {
    color: $color-info;
  }

  &--warning {
    color: $color-warning;
  }

  &--success {
    color: $color-success;
  }

  &--danger {
    color: $color-danger;
  }
}
