

.session-feedback {
  position: relative;
  text-align: center;

  &__question,
  &__result {
    transition: opacity $transition-duration $transition-timing, transform $transition-duration $transition-timing;
  }

  &__result {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateY(1em) scale(.9);
    transition-delay: $transition-duration / 2;
    width: 100%;
  }

  &__title {
    font-size: $font-size-sm;
    font-weight: 700;
    margin-bottom: $spacing;
  }

  &__select {
    margin: auto;
    max-width: 25em;
  }

  &__smallprint {
    color: $color-muted;
    font-size: $font-size-xs;
    font-weight: 400;
    margin-top: $spacing-sm;
  }

  &--completed {
    .session-feedback__question {
      opacity: 0;
      pointer-events: none;
      transform: translateY(-1em) scale(.9);
    }

    .session-feedback__result {
      opacity: 1;
      pointer-events: all;
      transform: none;
    }
  }
}
