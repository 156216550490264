.summary {
  align-items: flex-start;
  display: flex;
  padding: $spacing-xs 0;

  &__description {
    margin: $spacing-sm 0 0 0;

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    background: $color-grey-lightest;
    border: 1px solid $border-color;
    border-top-width: 0;
    padding: $spacing-sm $spacing;

    @media screen and (min-width: map-get($bp, md)) {
      padding-left: ($spacing * 2 + 2 * $font-size-lg);
    }
  }

  &--small {
    align-items: center;
  }
}
