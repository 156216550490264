

.section {
  background: $color-white;
  padding: $spacing-root-sm $spacing-root;

  @media screen and (min-width: map-get($bp, max)) {
    padding: $spacing-lg $spacing-xl;
  }

  &--info {
    background: $color-info-light;
  }

  &--warning {
    background: $color-warning-light;
  }

  &--success {
    background: $color-success-light;
  }

  &--danger {
    background: $color-danger-light;
  }

  &--muted {
    background: $color-grey-lightest;
  }
}
