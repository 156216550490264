

.form-control {
  margin-bottom: $spacing;

  &__label {
    font-size: $font-size-sm;
    font-weight: 400;
  }

  &__help {
    font-size: $font-size-sm;
    padding-top: $spacing-xs;

    &--error {
      color: $color-danger;
    }
  }

  &--horizontal {
    @media screen and (min-width: map-get($bp, tablet)) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;

      .form-control__label {
        align-self: center;
        grid-column: 1;
        grid-row: 1;
      }

      .form-control__input {
        grid-column: 2;
        grid-row: 1;
      }

      .form-control__help {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }

  &--error {
    input,
    select {
      border-color: $color-danger;
    }
  }
}
