


$transition-checkbox: transform $transition-duration $transition-timing, background $transition-duration $transition-timing;


.check {
  background: none;
  cursor: pointer;
  display: inline-block;
  user-select: none;

  &:focus {
    outline: none;
  }

  &__input {
    @include sr-only;
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    margin: 0;
    transition: color $transition-duration $transition-timing;
  }

  &__checkbox {
    background: $color-white;
    border: $border-width * 2 $border-style $color-green-dark;
    box-shadow: 0 0 0 0 $color-green-dark;
    display: inline-block;
    height: 1em;
    margin-left: $spacing-xs;
    text-align: center;
    transition: $transition-checkbox;
    vertical-align: -.375em;
    width: 1em;

    &:hover,
    .check:focus & {
      border-color: $color-green;
      transform: scale(1.1);
    }

    &:active {
      transform: scale(.95);
    }
  }

  &__icon {
    color: $color-white;
    left: -.125em;
    line-height: 1.25;
    margin: 0;
    position: relative;
    top: -.25em;
    transform: scale(0);
    transition: transform $transition-duration-boing $transition-timing-boing;
    vertical-align: baseline;
  }

  &--checked {
    .check__label {
      color: $color-green-dark;
    }

    .check__checkbox {
      background: $color-green-dark;
      border-color: $color-green-dark;
      box-shadow: 0 0 1em 1em fade-out($color-green-dark, 1);
      transform: scale(1);
      transition: $transition-checkbox, box-shadow $transition-duration $transition-timing;

      &:hover,
      .check:focus & {
        transform: scale(1);
      }
    }

    .check__icon {
      transform: scale(.75);
    }
  }

  &--disabled {
    .check__label,
    .check__checkbox {
      cursor: default;
      opacity: .7;
    }
  }

  @media screen and (min-width: map-get($bp, 'lg')) {
    &--solid {
      background: $color-darken;
      border-color: transparent;
      box-shadow: 0 0 0 0 $color-green-dark;
      padding: $spacing-xs $spacing-sm;
      transition: background $transition-duration $transition-timing, transform $transition-duration $transition-timing;

      .check__label {
        font-weight: bold;
        margin-right: $spacing-sm;
      }

      &:hover,
      &:focus {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(.95);
      }

      &.check--checked {
        background: $color-green-dark;
        box-shadow: 0 0 1em 1em fade-out($color-green-dark, 1);
        transition: box-shadow $transition-duration $transition-timing, background $transition-duration $transition-timing, transform $transition-duration $transition-timing;

        &:hover,
        &:focus {
          transform: scale(1);
        }

        .check__label {
          color: $color-white;
        }

        .check__checkbox {
          background: $color-darken;
          border-color: transparent;
          transition: $transition-checkbox;
        }

        .check__checkbox:hover {
          transform: scale(1);
        }
      }
    }
  }
}
