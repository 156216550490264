

@keyframes icon-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon {
  display: inline-block;
  height: 1em;
  min-width: 1em;
  text-align: center;
  vertical-align: baseline;

  &--hidden {
    visibility: hidden;
  }

  &--spin {
    animation: icon-spin .75s infinite linear;
  }

  // States

  &--info {
    color: $color-info;
  }

  &--warning {
    color: $color-warning;
  }

  &--success {
    color: $color-success;
  }

  &--danger {
    color: $color-danger;
  }

  // Sizes

  &--base {
    font-size: $font-size-base;
  }

  &--xs {
    font-size: $font-size-xs;
  }

  &--sm {
    font-size: $font-size-sm;
  }

  &--lg {
    font-size: $font-size-lg;
  }

  &--xl {
    font-size: $font-size-xl;
  }

  &--circle,
  &--circle-outline {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    height: 1.5em;
    justify-content: center;
    vertical-align: top;
    width: 1.5em;

    &::before {
      font-size: .5em; // Correct ratio against the outer circle
    }
  }

  &--circle {
    background: currentColor;

    &::before {
      color: $color-white;
    }
  }

  &--circle-outline {
    border: $border-width-lg solid currentColor;
  }

  // Themes
  @include themeColor;
}
