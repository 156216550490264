@import 'base/functions';

// Variables
//
// Variable format should follow the $[element]-[property]-[orientation]-[size] order.

// Colors
// ==================================================

$color-brand-purple: rgb(114, 36, 108);
$color-brand-white: rgb(255, 255, 255);
$color-brand-black: rgb(0, 0, 0);
$color-brand-grey-dark: rgb(99, 102, 106);
$color-brand-grey: rgb(136, 138, 141);
$color-brand-grey-light: rgb(187, 188, 188);

$color-brand-primary-orange: rgb(240, 179, 35);
$color-brand-primary-blue: rgb(65, 182, 230);
$color-brand-primary-green: rgb(72, 162, 63);

$color-brand-secondary-orange: rgb(220, 107, 47);
$color-brand-secondary-blue: rgb(58, 93, 174);
$color-brand-secondary-green: rgb(0, 133, 125);

$gradient-purple: linear-gradient(to right, rgb(103,45,137) 20%, rgb(169,63,107) 100%);
$gradient-purple-diagonal: linear-gradient(45deg, rgb(103,45,137) 20%, rgb(169,63,107) 100%);
$gradient-blue: linear-gradient(to right, rgb(58,93,174) 0%, rgb(65,182,230) 100%);
$gradient-blue-diagonal: linear-gradient(45deg, rgb(58,93,174) 0%, rgb(65,182,230) 100%);
$gradient-orange: linear-gradient(to right, rgb(220,107,47) 0%, rgb(240,179,35) 100%);
$gradient-orange-diagonal: linear-gradient(45deg, rgb(220,107,47) 0%, rgb(240,179,35) 100%);
$gradient-green: linear-gradient(to right, rgb(0,133,125) 0%, rgb(72,162,63) 100%);
$gradient-green-diagonal: linear-gradient(45deg, rgb(0,133,125) 0%,rgb(72,162,63) 100%);
$gradient-black: linear-gradient(to right, rgb(0,0,0) 0%, rgb(68,68,68) 100%);
$gradient-black-diagonal: linear-gradient(45deg, rgb(0,0,0) 0%,rgb(68,68,68) 100%);

$color-brand-primary: $color-brand-purple;

$color-blue: $color-brand-primary-blue;
$color-blue-dark: $color-brand-secondary-blue;

$color-green: $color-brand-primary-green;
$color-green-dark: $color-brand-secondary-green;

$color-orange: $color-brand-primary-orange;
$color-orange-dark: $color-brand-secondary-orange;

$color-white: $color-brand-white;
$color-grey-light: $color-brand-grey-light;
$color-grey-lighter: lighten($color-grey-light, 15%);
$color-grey-lightest: #eeeeef;
$color-grey-more-lightest: #f7f8fa; // oops
$color-grey: $color-brand-grey;
$color-grey-dark: $color-brand-grey-dark;
$color-grey-darker: darken($color-grey-dark, 20%);
$color-black: $color-brand-black;

$color-darken: rgba(0, 0, 0, .075);
$color-darken-more: rgba(0, 0, 0, .125);
$color-darken-more-again: rgba(0, 0, 0, .25);
$color-darken-lots: rgba(0, 0, 0, .75);

$color-lighten: rgba(255, 255, 255, .1);
$color-lighten-more: rgba(255, 255, 255, .5);
$color-lighten-lots: rgba(255, 255, 255, .75);

$color-note: #fff6de;

$color-muted: $color-grey-dark;

// States

$color-success: $color-green-dark;
$color-success-light: tint($color-green-dark, 90%);

$color-info: $color-blue;
$color-info-light: tint($color-blue, 90%);

$color-warning: $color-orange;
$color-warning-light: tint($color-orange, 90%);

$color-danger: $color-orange-dark;
$color-danger-light: tint($color-orange-dark, 90%);
