

.notes-list {
  margin: 0 (-$spacing-root) $spacing;

  @media screen and (min-width: map-get($bp, md)) {
    margin: 0;
  }

  &__item {
    border: $border;
    cursor: pointer;
    margin: 0 (-$border-width) (-$border-width);
    padding: $spacing $spacing;

    @media screen and (min-width: map-get($bp, md)) {
      margin: 0 0 $spacing;
    }
  }

  &__item-title {
    font-size: $font-size-base;
    font-weight: normal;
    margin-bottom: $spacing-sm;
  }

  &__loading-bar {
    background: $color-darken;
    height: 1em;
    margin-bottom: $spacing-sm;

    &:first-child {
      max-width: 7.5em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
