

.subject-status {
  display: flex;
  margin-bottom: $spacing;
  padding: $spacing $spacing;

  &__icon {

  }

  &__text {
    flex: 1 1 auto;
    min-width: 0;
    padding: 0 $spacing-sm;
  }

  &__action {
    cursor: pointer;
  }

  &--passed {
    display: none;
  }

  &--focus {
    background: $color-grey-lightest;
    display: flex;

    .subject-status__icon {
      .icon {
        background: $color-orange;
        border-radius: 50%;
        color: $color-white;
        height: 1.5em;
        padding: .25em;
        width: 1.5em;
      }
    }
  }
}
