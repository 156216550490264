

.footer {
  margin-top: $spacing-fluid-sm;

  &--logged-in {
    height: ($spacing * 2) + (1em * $line-height);
    opacity: 0;
    overflow: hidden;
    padding: $spacing $spacing;
  }

  &__prepend {
    margin: 0 auto;
    max-width: $container-max-width;
  }

  &__content {
    color: $color-brand-grey-dark;
  }

  &__inner {
    background: $color-white;
    margin: 0 auto;
    max-width: $container-max-width;
    padding: $spacing $spacing;

    @media screen and (min-width: map-get($bp, tablet)) {
      padding: $spacing $spacing-fluid-sm;
    }

    @media screen and (min-width: map-get($bp, desktop)) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  &__links-list {
    font-size: $font-size-sm;
    list-style: none;
    margin: 0 0 $spacing;
    padding: 0;

    &-item {
      display: inline-block;
      margin-right: $spacing-lg;

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      color: inherit;
      display: inline-block;
      padding: $spacing 0;
      text-decoration: none;

      @media screen and (min-width: map-get($bp, desktop)) {
        padding: 0;
      }

      &:hover {
        color: $link-color;
        text-decoration: underline;
      }
    }
  }

  &__copyright {
    font-size: $font-size-xs;
  }
}
