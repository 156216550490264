.image {
  &--block {
    display: block;
  }

  &--cover {
    object-fit: cover;
  }

  &--contain {
    object-fit: contain;
  }

  &--aspect-ratio {
    &-1 {
      aspect-ratio: 1;
    }
  }

  &--fill-height {
    height: 100%;
  }

  &--fill-width {
    width: 100%;
  }
}
