

.logo {
  color: $color-brand-primary;
  font-size: 1.25rem;
  line-height: 1.125;
  white-space: nowrap;

  &__r {
    font-size: .5em;
    vertical-align: super;
  }

  @media screen and (min-width: map-get($bp, desktop)) {
    font-size: 1.5rem;

    &__r {
      font-size: .25em;
      vertical-align: 2em;
    }
  }
}
