

.progress-update-content {
  background: $color-white;
  padding: 0 $spacing-fluid;
  width: 100%;

  &__subject {
    margin-bottom: $spacing-lg;
  }

  &__folder {
    margin-right: $spacing-sm;
  }

  &__title {
    font-weight: bold;
  }

  &__passed {
    color: $color-success;
  }

  &__list {
    margin: $spacing 0 0;
  }
}
