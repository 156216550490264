

.empty {
  color: $color-brand-grey;
  margin: 0 auto;
  max-width: 14em;
  padding: ($spacing * 5) 0;
  text-align: center;


  &__icon {
    font-size: $font-size-lg * 2;
    margin-bottom: $spacing-xs;
  }
}
