.format-icon {
  background: $color-grey;
  border-radius: 50%;
  color: $color-white;
  font-size: $font-size-lg;
  height: 2em;
  line-height: 1;
  padding: .5em;
  width: 2em;

  .icon {
    bottom: .05em;
    position: relative;
  }

  @include themeBackground;
}
