.hamburger {
  background: none;
  border: 0 none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: $spacing-thumb;
  margin: 0;
  padding: 1.125rem 1rem;
  transition: background-color $transition-duration $transition-timing;
  width: $spacing-thumb;

  &__line {
    background: $color-brand-primary;
    border-radius: .125rem;
    display: block;
    height: .125rem;
    transition: transform $transition-duration $transition-timing;
    transform-origin: center;
    width: 100%;
  }

  > &__line {
    &:nth-child(1),
    &:nth-child(3) {
      transition: opacity $transition-duration $transition-timing;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: transparentize($color-brand-primary, 0.975);
  }

  &:active {
    background-color: transparentize($color-brand-primary, 0.975);
  }

  &--open {
    > .hamburger__line {
      &:nth-child(1),
      &:nth-child(3) {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);

        .hamburger__line {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
