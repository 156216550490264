$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-play: "\f04b";
$icon-pause: "\f04c";
$icon-stop: "\f04d";
$icon-search: "\f002";
$icon-user: "\f007";
$icon-th-list: "\f00b";
$icon-check: "\f00c";
$icon-close: "\f00d";
$icon-remove: "\f00d";
$icon-times: "\f00d";
$icon-search-plus: "\f00e";
$icon-search-minus: "\f010";
$icon-cog: "\f013";
$icon-gear: "\f013";
$icon-trash-o: "\f014";
$icon-home: "\f015";
$icon-file-o: "\f016";
$icon-clock-o: "\f017";
$icon-download: "\f019";
$icon-refresh: "\f021";
$icon-list-alt: "\f022";
$icon-flag: "\f024";
$icon-bookmark: "\f02e";
$icon-print: "\f02f";
$icon-image: "\f03e";
$icon-photo: "\f03e";
$icon-picture-o: "\f03e";
$icon-pencil: "\f040";
$icon-edit: "\f044";
$icon-pencil-square-o: "\f044";
$icon-plus-circle: "\f055";
$icon-minus-circle: "\f056";
$icon-times-circle: "\f057";
$icon-check-circle: "\f058";
$icon-question-circle: "\f059";
$icon-info-circle: "\f05a";
$icon-times-circle-o: "\f05c";
$icon-ban: "\f05e";
$icon-plus: "\f067";
$icon-minus: "\f068";
$icon-eye: "\f06e";
$icon-exclamation-triangle: "\f071";
$icon-warning: "\f071";
$icon-random: "\f074";
$icon-comment: "\f075";
$icon-folder: "\f07b";
$icon-folder-open: "\f07c";
$icon-bar-chart: "\f080";
$icon-bar-chart-o: "\f080";
$icon-twitter-square: "\f081";
$icon-facebook-square: "\f082";
$icon-cogs: "\f085";
$icon-gears: "\f085";
$icon-comments: "\f086";
$icon-star-half: "\f089";
$icon-heart-o: "\f08a";
$icon-sign-out: "\f08b";
$icon-linkedin-square: "\f08c";
$icon-sign-in: "\f090";
$icon-trophy: "\f091";
$icon-upload: "\f093";
$icon-square-o: "\f096";
$icon-bookmark-o: "\f097";
$icon-phone-square: "\f098";
$icon-twitter: "\f099";
$icon-facebook: "\f09a";
$icon-facebook-f: "\f09a";
$icon-unlock: "\f09c";
$icon-bullhorn: "\f0a1";
$icon-bell-o: "\f0a2";
$icon-certificate: "\f0a3";
$icon-arrow-circle-left: "\f0a8";
$icon-arrow-circle-right: "\f0a9";
$icon-arrow-circle-up: "\f0aa";
$icon-arrow-circle-down: "\f0ab";
$icon-globe: "\f0ac";
$icon-wrench: "\f0ad";
$icon-group: "\f0c0";
$icon-users: "\f0c0";
$icon-chain: "\f0c1";
$icon-link: "\f0c1";
$icon-cloud: "\f0c2";
$icon-paperclip: "\f0c6";
$icon-floppy-o: "\f0c7";
$icon-save: "\f0c7";
$icon-square: "\f0c8";
$icon-bars: "\f0c9";
$icon-navicon: "\f0c9";
$icon-reorder: "\f0c9";
$icon-table: "\f0ce";
$icon-caret-down: "\f0d7";
$icon-caret-up: "\f0d8";
$icon-caret-left: "\f0d9";
$icon-caret-right: "\f0da";
$icon-sort: "\f0dc";
$icon-unsorted: "\f0dc";
$icon-sort-desc: "\f0dd";
$icon-sort-down: "\f0dd";
$icon-sort-asc: "\f0de";
$icon-sort-up: "\f0de";
$icon-envelope: "\f0e0";
$icon-linkedin: "\f0e1";
$icon-rotate-left: "\f0e2";
$icon-undo: "\f0e2";
$icon-comment-o: "\f0e5";
$icon-exchange: "\f0ec";
$icon-cloud-download: "\f0ed";
$icon-cloud-upload: "\f0ee";
$icon-bell: "\f0f3";
$icon-file-text-o: "\f0f6";
$icon-plus-square: "\f0fe";
$icon-circle-o: "\f10c";
$icon-spinner: "\f110";
$icon-circle: "\f111";
$icon-folder-o: "\f114";
$icon-folder-open-o: "\f115";
$icon-smile-o: "\f118";
$icon-frown-o: "\f119";
$icon-meh-o: "\f11a";
$icon-flag-o: "\f11d";
$icon-star-half-empty: "\f123";
$icon-star-half-full: "\f123";
$icon-star-half-o: "\f123";
$icon-question: "\f128";
$icon-info: "\f129";
$icon-exclamation: "\f12a";
$icon-calendar-o: "\f133";
$icon-rocket: "\f135";
$icon-arrow-circle-left2: "\f137";
$icon-arrow-circle-right2: "\f138";
$icon-arrow-circle-up2: "\f139";
$icon-arrow-circle-down2: "\f13a";
$icon-unlock-alt: "\f13e";
$icon-play-circle: "\f144";
$icon-minus-square: "\f146";
$icon-minus-square-o: "\f147";
$icon-pencil-square: "\f14b";
$icon-external-link-square: "\f14c";
$icon-share-square: "\f14d";
$icon-caret-square-o-down: "\f150";
$icon-toggle-down: "\f150";
$icon-caret-square-o-up: "\f151";
$icon-toggle-up: "\f151";
$icon-caret-square-o-right: "\f152";
$icon-toggle-right: "\f152";
$icon-file: "\f15b";
$icon-file-text: "\f15c";
$icon-youtube-square: "\f166";
$icon-youtube-play: "\f16a";
$icon-instagram: "\f16d";
$icon-long-arrow-down: "\f175";
$icon-long-arrow-up: "\f176";
$icon-long-arrow-left: "\f177";
$icon-long-arrow-right: "\f178";
$icon-arrow-circle-o-right: "\f18e";
$icon-arrow-circle-o-left: "\f190";
$icon-caret-square-o-left: "\f191";
$icon-toggle-left: "\f191";
$icon-dot-circle-o: "\f192";
$icon-plus-square-o: "\f196";
$icon-envelope-square: "\f199";
$icon-graduation-cap: "\f19d";
$icon-mortar-board: "\f19d";
$icon-building: "\f1ad";
$icon-file-pdf-o: "\f1c1";
$icon-file-word-o: "\f1c2";
$icon-file-excel-o: "\f1c3";
$icon-file-powerpoint-o: "\f1c4";
$icon-file-image-o: "\f1c5";
$icon-file-photo-o: "\f1c5";
$icon-file-picture-o: "\f1c5";
$icon-file-archive-o: "\f1c6";
$icon-file-zip-o: "\f1c6";
$icon-file-audio-o: "\f1c7";
$icon-file-sound-o: "\f1c7";
$icon-file-movie-o: "\f1c8";
$icon-file-video-o: "\f1c8";
$icon-file-code-o: "\f1c9";
$icon-life-bouy: "\f1cd";
$icon-life-buoy: "\f1cd";
$icon-life-ring: "\f1cd";
$icon-life-saver: "\f1cd";
$icon-support: "\f1cd";
$icon-paper-plane: "\f1d8";
$icon-send: "\f1d8";
$icon-paper-plane-o: "\f1d9";
$icon-send-o: "\f1d9";
$icon-circle-thin: "\f1db";
$icon-sliders: "\f1de";
$icon-newspaper-o: "\f1ea";
$icon-calculator: "\f1ec";
$icon-trash: "\f1f8";
$icon-at: "\f1fa";
$icon-area-chart: "\f1fe";
$icon-pie-chart: "\f200";
$icon-line-chart: "\f201";
$icon-toggle-off: "\f204";
$icon-toggle-on: "\f205";
$icon-genderless: "\f22d";
$icon-facebook-official: "\f230";
$icon-pinterest-p: "\f231";
$icon-user-plus: "\f234";
$icon-user-times: "\f235";
$icon-calendar-plus-o: "\f271";
$icon-calendar-minus-o: "\f272";
$icon-calendar-times-o: "\f273";
$icon-calendar-check-o: "\f274";
$icon-article: "\e911";
$icon-course: "\e913";
$icon-dashboard: "\e919";
$icon-login: "\e91a";
$icon-logout: "\e91b";
$icon-options: "\e91c";
$icon-resource: "\e91d";
$icon-settings: "\e91f";
$icon-study-support: "\e920";
$icon-wellbeing: "\e910";
$icon-webinar: "\e90f";
$icon-resource_digital-text: "\e906";
$icon-resource-discission: "\e90d";
$icon-resource_digital-interactive: "\e90e";
$icon-resource_physical-text: "\e912";
$icon-resource-velocity-text: "\e914";
$icon-resource-fm-text: "\e915";
$icon-resource-cgma-text: "\e916";
$icon-resource-connect-text: "\e917";
$icon-resource-default-text: "\e918";
$icon-stopwatch: "\e931";
$icon-badge: "\e932";
$icon-grid: "\e905";
$icon-calendar: "\e900";
$icon-date: "\e900";
$icon-schedule: "\e900";
$icon-arrow-down: "\e901";
$icon-arrow-left: "\e902";
$icon-arrow-right: "\e903";
$icon-arrow-up: "\e904";
$icon-chevron-down: "\e907";
$icon-chevron-left: "\e908";
$icon-chevron-right: "\e909";
$icon-chevron-up: "\e90a";
$icon-clock: "\e90b";
$icon-external-link: "\e90c";

