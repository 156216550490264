

.no-notes {
  display: flex;

  &__graphic {
    display: none;
    width: 33.3333333%;
    margin-right: $spacing-lg;

    @media screen and (min-width: map-get($bp, tablet)) {
      display: block;
    }
  }

  &__content {
    @media screen and (min-width: map-get($bp, tablet)) {
      margin-top: $spacing;
      width: 66.6666666%;
    }
  }

  &__info {
    display: flex;
    margin-bottom: $spacing;

    &-icon {
      font-size: ($font-size-lg * 1.5);
      color: $color-brand-primary-orange;
      margin-right: $spacing-sm;
    }

    &-content {
      flex-grow: 1;
    }
  }
}
