

.title {
  &--spacing {
    margin-bottom: $spacing-sm;
  }

  // Themes
  &--brand-primary {
    color: $color-brand-primary;
  }
  &--brand-grey {
    color: $color-brand-grey;
  }
  &--blue {
    color: $color-blue;
  }
  &--blue-dark {
    color: $color-blue-dark;
  }
  &--green {
    color: $color-green;
  }
  &--green-dark {
    color: $color-green-dark;
  }
  &--orange {
    color: $color-orange;
  }
  &--orange-dark {
    color: $color-orange-dark;
  }
  &--black {
    color: $color-brand-black;
  }
  &--white {
    color: $color-white;
  }

  &--h1 {
    font-size: $h1-font-size;
  }

  &--h2 {
    font-size: $h2-font-size;
  }

  &--h3 {
    font-size: $h3-font-size;
  }

  &--h4 {
    font-size: $h4-font-size;
  }

  &--responsive {
    @media screen and (min-width: map-get($bp, desktop)) {
      &.title--h1 {
        font-size: 3.25em;
      }
    }

    @media screen and (min-width: map-get($bp, tablet)) {
      &.title--h2 {
        font-size: $h1-font-size;
      }

      &.title--h3 {
        font-size: $h2-font-size;
      }

      &.title--h4 {
        font-size: $h3-font-size;
      }
    }
  }

  &--weight-normal {
    font-weight: normal;
  }

  &--weight-bold {
    font-weight: bold;
  }
}
