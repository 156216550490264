

.subjects-nav {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: (-$spacing / 2) (-$spacing / 2);
  padding: 0;

  &__item {
    padding: $spacing / 2 $spacing / 2;
  }

  &__link {
    cursor: pointer;
    display: block;
    transition: transform $transition-duration $transition-timing;

    &:hover {
      transform: scale(1.05);
    }
  }
}
