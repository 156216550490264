

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__item {
    margin-bottom: $spacing-fluid-sm;
  }

  &--inset {
    padding: $spacing $spacing;
  }

  &--outset {
    margin: 0 (-$spacing);
  }

  &--spacing {
    &-xs {
      .list__item {
        margin-bottom: $spacing-xs;
      }
    }

    &-sm {
      .list__item {
        margin-bottom: $spacing-sm;
      }
    }

    &-md {
      .list__item {
        margin-bottom: $spacing-md;
      }
    }

    &-lg {
      .list__item {
        margin-bottom: $spacing-lg;
      }
    }

    &-xl {
      .list__item {
        margin-bottom: $spacing-xl;
      }
    }
  }
}
