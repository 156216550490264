


.session-summary {
  align-items: baseline;
  display: flex;
  margin: (-$spacing-sm / 2) (-$spacing-sm / 2);

  &__number,
  &__content {
    padding: $spacing-fluid-sm 0;
  }

  &__number {
    padding-left: $spacing-sm;
    padding-right: $spacing;

    @media screen and (min-width: map-get($bp, tablet)) {
      font-size: $h3-font-size;
    }
  }

  &__number-label {
    @media screen {
      @include sr-only;
    }
  }

  &__description {
    font-size: $font-size-md;

    @media screen and (min-width: map-get($bp, tablet)) {
      font-size: $font-size-lg;
    }
  }

  &__hours {
    font-size: $font-size-sm;
    margin-top: $spacing-sm;
  }

  &__note {
    background: $color-note;
    border-radius: 3px;
    display: inline-block;
    margin-right: $spacing-xs;
    text-align: center;
    width: 1.25em;
  }

  &__hours {
    flex: 0 0 auto;
    font-weight: 700;
    white-space: nowrap;
  }

  @media screen and (min-width: map-get($bp, desktop)) {
    align-items: flex-start;
    flex-wrap: nowrap;

    &__description {
      flex: 1 1 auto;
      order: 2;
      width: auto;
    }

    &__hours {
      order: 3;
    }
  }
}
