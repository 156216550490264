.category-button {
  background-color: $color-grey;
  background-position: center;
  background-size: cover;
  border-radius: .5em;
  color: $color-white;
  cursor: pointer;
  display: block;
  padding: $spacing $spacing;
  width: 100%;

  &:focus {
    outline-width: thin;
  }

  &:hover {
    box-shadow: 0 0 .25em 0 $color-darken;
  }

  &:active {
    box-shadow: none;
  }

  &__arrow {
    float: right;
    margin: 0 0 $spacing-xs $spacing-sm;
    transition: transform $transition-duration $transition-timing;

    .category-button:hover & {
      transform: translateX(.25em);
    }
  }

  &__title {
    font-size: $font-size-lg;
    margin-bottom: $spacing-xs;
  }

  &__count {
    font-weight: 700;
  }

  &--large {
    padding-bottom: 2em;
  }

  @include themeBackground;

  &--gradient-purple {
    background-image: url('/images/categories/subjects.jpg');
  }

  &--gradient-orange {
    background-image: url('/images/categories/exams.jpg');
  }

  &--gradient-blue {
    background-image: url('/images/categories/support.jpg');
  }

  &--gradient-green {
    background-image: url('/images/categories/upcoming.jpg');
  }

  &--life-gradient-purple {
    background-image: url('/images/categories/life.jpg');
  }

  &--body-gradient-blue {
    background-image: url('/images/categories/body.jpg');
  }

  &--mind-gradient-green {
    background-image: url('/images/categories/mind.jpg');
  }

  &--disabled {
    filter: grayscale(0.75);
    pointer-events: none;
  }
}
