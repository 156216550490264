

.notes {
  background: $color-white;

  &__header {
    padding: $spacing-root-sm $spacing-root 0;
  }

  &__main {
    background: $color-note;
    padding: $spacing-root-sm $spacing-root;
  }

  &__sidebar {
    display: none;
  }

  &__subject-code {
    display: inline-block;
    margin-left: (-$spacing-root);
    padding: $spacing-sm $spacing-lg $spacing-sm $spacing-root;
    transition: padding $transition-duration $transition-timing;

    // @media screen and (min-width: map-get($bp, desktop)) {
    //   padding: $spacing $spacing-lg $spacing $spacing-root;
    // }

    @media screen and (min-width: map-get($bp, max)) {
      margin-left: (-$spacing-xl);
      padding-left: $spacing-xl;
    }

    &-title {
      color: $color-white;
      font-size: 1.5em;
      font-weight: 300;

      @media screen and (min-width: map-get($bp, desktop)) {
        font-size: 2em;
      }
    }
  }

  @media screen and (min-width: map-get($bp, desktop)) {
    &__inner {
      display: flex;
    }

    &__header {
      padding: $spacing-root-sm $spacing-lg 0;
    }

    &__sidebar {
      display: block;
      width: 33.33%;
    }

    &__main {
      min-height: 50vh;
      width: 66.66%;
    }
  }

  @media screen and (min-width: map-get($bp, max)) {
    &__header {
      padding: $spacing-lg $spacing-lg $spacing;
    }

    &__main {
      padding: $spacing-lg $spacing-xl;
    }
  }
}
