.article-hero {
  background-color: $color-brand-primary;
  background-position: center;
  background-size: 100%;
  color: $color-white;
  padding: $spacing-fluid $spacing-fluid;

  @include themeBackground;

  &__icon {
    background: $color-white;
    border-radius: 50%;
    font-size: 1.5em;
    display: none;
    height: 2em;
    line-height: 1;
    margin-right: $spacing * 1em / $font-size-lg;
    padding: .5em;
    position: absolute;
    width: 2em;

    @media screen and (min-width: map-get($bp, xl)) {
      display: block;
    }

    @media screen and (min-width: map-get($bp, desktop)) {
      font-size: 1.75em;
    }

    @media screen and (min-width: map-get($bp, max)) {
      font-size: 2em;
    }

    .icon {
      background-clip: text;
      background-color: $color-brand-primary;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      text-fill-color: transparent;
      bottom: .05em;
      position: relative;
    }

    &--gradient-purple {
      .icon {
        background-image: $gradient-purple;
      }

      &-diagonal {
        .icon {
          background-image: $gradient-purple-diagonal;
        }
      }
    }

    &--gradient-blue {
      .icon {
        background-image: $gradient-blue;
      }

      &-diagonal {
        .icon {
          background-image: $gradient-blue-diagonal;
        }
      }
    }

    &--gradient-orange {
      .icon {
        background-image: $gradient-orange;
      }

      &-diagonal {
        .icon {
          background-image: $gradient-orange-diagonal;
        }
      }
    }
  }

  &__content {
    // Matches page-main padding
    @media only screen and (min-width: map-get($bp, desktop)) {
      padding: 0 ($spacing-root / 2);
    }

    @media only screen and (min-width: map-get($bp, max)) {
      padding: 0 ($spacing-xl / 2);
    }
  }

  &__eyebrow {
    font-weight: 700;
  }

  &__title {
    font-weight: 700;
  }

  &--small {
    align-items: center;
  }

  &--gradient-purple {
    background-image: url('/images/categories/subjects.jpg');
  }

  &--gradient-orange {
    background-image: url('/images/categories/exams.jpg');
  }

  &--gradient-blue {
    background-image: url('/images/categories/support.jpg');
  }
}
