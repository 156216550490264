

.note-modal {
  background: $color-note;
  min-height: 100vh;

  &__header {
    align-content: center;
    background: $color-note;
    border-bottom: 1px solid $border-color;
    display: flex;
    position: sticky;
    top: 0;
  }

  &__title {
    align-self: center;
    flex: 1 1 auto;
    min-width: 0;
    padding: $spacing-sm $spacing;
  }

  &__done {
    border-left: 1px solid $border-color;
  }

  @media screen and (min-width: map-get($bp, tablet)) {
    min-height: auto;

    &__title {
      padding: $spacing $spacing-lg;
    }

    &__done {
      .button {
        padding: $spacing $spacing-lg;
      }
    }
  }
}
