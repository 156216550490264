

@keyframes success-tick-circle {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes success-tick-icon {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(.9);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.success-tick {
  background: $color-success;
  border-radius: 50%;
  color: $color-white;
  display: inline-block;
  height: 1.25em;
  position: relative;
  width: 1.25em;

  .icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) ;
  }

  &--animated {
    animation: success-tick-circle $transition-duration-boing $transition-timing-boing 1 backwards;
    animation-delay: $transition-duration * 2; // Allow modal to enter first

    .icon {
      animation: success-tick-icon $transition-duration-boing $transition-timing-boing 1 backwards;
      animation-delay: $transition-duration * 2 + $transition-duration-boing / 2;
    }
  }
}
