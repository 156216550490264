

@keyframes context-title {
  0% {
    opacity: 0;
    transform: translate(-.5em, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.context-title {
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: context-title;
  animation-timing-function: $transition-timing;
  box-sizing: content-box;
  display: flex;
  margin: (-$spacing) (-$spacing);
  max-width: 100%;
  overflow: hidden;
  padding: $spacing $spacing;
  text-overflow: ellipsis;
  white-space: nowrap;

  .context--logged-in &,
  .context--logged-in & > * {
    font-size: 1rem;
    font-weight: 400;
    line-height: $line-height;
  }

  h1 {
    padding: $spacing-root 0;

    .context--logged-in & {
      padding: 0;
    }
  }

  .unstyled-a {
    margin-left: -($spacing * .75);
  }
}
