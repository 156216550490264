

.case-study-summary {
  &__case-study-link {
    border-top: $border-width $border-style $border-color;
    color: inherit;
    display: block;
    margin: 0 (-$spacing);
    padding: $spacing $spacing;

    &:hover,
    &:focus {
      background-color: #f6f6f6;
    }

    @media screen and (min-width: map-get($bp, desktop)) {
      margin: 0 (-$spacing-lg);
      padding: $spacing $spacing-lg;
    }
  }

  &__window {
    display: flex;

    &-icon {
      color: $color-orange-dark;
      font-size: $font-size-lg * 2;
      line-height: 1.2;
    }

    &-content {
      margin-left: $spacing * 1.5;
      flex-grow: 1;
    }
  }
}
