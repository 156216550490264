

.modal {
  -webkit-overflow-scrolling: touch;
  background: $color-white;
  box-shadow: 0 0 5px 0 $color-darken;
  max-height: 100%;
  max-width: map-get($bp, tablet);
  opacity: 0;
  outline: none;
  overflow: auto;
  transform: scale(0.9);
  transition: opacity $transition-duration $transition-timing, transform $transition-duration $transition-timing;
  transition-delay: $transition-duration;
  width: 100%;

  &--full-height {
    min-height: 100vh;
    min-height: stretch; // Prevent height jump on Android Chrome
  }

  @media screen and (min-width: map-get($bp, tablet)) {
    margin: $spacing $spacing;

    &--full-height {
      min-height: auto;
    }
  }

  &__close {
    appearance: none;
    background: none;
    border: 0 none;
    border-radius: $border-radius;
    color: $color-grey;
    cursor: pointer;
    float: right;
    margin: $spacing-fluid-sm $spacing-fluid-sm 0 0;
    padding: $spacing $spacing;
    transition: background-color $transition-duration $transition-timing;

    .icon {
      font-size: $font-size-lg;
    }

    &:hover,
    &:focus {
      background: $color-darken;
      color: $link-hover-color;
      outline: 0 none;
    }

    &:active {
      background: $color-darken-more;
      color: $link-active-color;
    }
  }

  &.ReactModal__Content--after-open { // sass-lint:disable-line class-name-format
    opacity: 1;
    transform: none;
  }

  &.ReactModal__Content--before-close { // sass-lint:disable-line class-name-format
    opacity: 0;
    transform: scale(0.9);
    transition-delay: 0s;
  }

  &__overlay {
    align-items: center;
    background-color: transparent;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: background-color $transition-duration $transition-timing;
    z-index: $max-z-index;

    &.ReactModal__Overlay--after-open { // sass-lint:disable-line class-name-format
      background-color: transparentize($body-bg, .5);
    }

    &.ReactModal__Overlay--before-close { // sass-lint:disable-line class-name-format
      background-color: transparent;
      transition-delay: $transition-duration;
    }
  }

  &__inner {
    padding: $spacing-fluid $spacing-fluid;
  }

  &__flush {
    &--vertical {
      margin-bottom: (-($spacing-fluid * 100%/(100% - $spacing-fluid * 2)));
      margin-top: (-($spacing-fluid * 100%/(100% - $spacing-fluid * 2)));
    }

    &--horizontal {
      margin-left: (-($spacing-fluid * 100%/(100% -$spacing-fluid * 2)));
      margin-right: (-($spacing-fluid * 100%/(100% -$spacing-fluid * 2)));
    }
  }

  // @TODO probs need a modifier on .button
  .button {
    min-width: 6em;
  }

  @media screen and (min-width: map-get($bp, tablet)) {
    // Sizes

    &--small {
      max-width: 32em;
    }

    &--large {
      max-width: 48em;
    }
  }
}

.ReactModal__Body--open { // sass-lint:disable-line class-name-format
  height: 100vh;
  overflow: hidden;
}
