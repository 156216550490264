

$overlap-height: $spacing-lg; // used to pull page content over the top of the context header

.context {
  align-items: center;
  background: $color-brand-purple;
  color: $color-white;
  display: flex;
  justify-content: center;
  margin-left: 50%;
  min-height: 3.5em;
  transform: translate(-50%, 0);
  width: 100vw;

  &::after { // IE11 vertical alignment fix
    content: '';
    display: block;
    font-size: 0;
    min-height: inherit;
  }

  &__inner {
    margin: 0;
    max-width: $container-max-width;
    width: 100%;
  }

  &__content {
    padding: 0 $spacing-fluid;
  }

  @media screen and (min-width: map-get($bp, max)) {
    margin-bottom: -$overlap-height;
    min-height: 7em;
    padding-bottom: $overlap-height;

    &--no-shift {
      margin-bottom: 0;
      min-height: 5em;
      padding-bottom: 0;
    }
  }

  &--logged-in {
    height: auto;
    margin-bottom: 0; // Remove overlap
    min-height: 3.5em;
    padding-bottom: 0;

    .context__content {
      padding: 0 $spacing;
    }
  }

  // Themes
  @include themeBackground;

  .unstyled-a,
  .unstyled-button {
    border-radius: $border-radius;
    display: inline-block;
    margin: (-$spacing * .75) (-$spacing * .7);
    padding: $spacing-sm $spacing;
    transition: background-color $transition-duration $transition-timing;

    // Might be worth making this into a <BackArrow>
    .icon-arrow-left {
      margin-right: $spacing-sm;
      transform: translateX(0);
      transition: transform $transition-duration $transition-timing
    }

    &:hover,
    &:focus {
      background-color: $color-darken-more;
      .icon-arrow-left {
        transform: translateX(-.25em);
      }
    }
  }
}
