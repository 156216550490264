

.note {
  background: $color-note;
  max-height: 100%;
  min-height: ($spacing * 2 + $line-height * 1em);
  max-height: 12em;
  overflow: hidden;
  padding: $spacing $spacing;
  position: relative;
  transition: max-height $transition-duration $transition-timing;

  &::after {
    background: linear-gradient(to bottom, transparentize($color-note, 1) 0%, $color-note 75%, $color-note 100%);
    bottom: 0;
    content: '';
    height: $spacing * 2;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .note__pre {
    font-family: inherit;
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
  }

  button & {
    &:hover {
      max-height: 13em;
    }
  }
}
