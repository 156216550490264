

.product-summary {

  &__image {

    img {
      display: block;
      margin: 0 auto;
      max-height: 50vh;
    }
  }

  &__content {
    padding: $spacing $spacing;
  }

  @media screen and (min-width: map-get($bp, md)) {
    display: flex;

    &__image {
      min-width: 25%;
      width: 25%;
    }
  }

  &__title {
    margin: 0 0 $spacing-sm;
  }

  &__description {
    flex-grow: 2;
  }

  &__price {
    background: $color-brand-purple;
    color: $color-white;
    display: inline-block;
    float: right;
    font-size: .625em;
    margin-right: -$spacing;
    padding: $spacing-xs $spacing $spacing-xs $spacing;
  }

  &__meta {
    padding-top: $spacing-sm;

    &-item {
      color: $color-brand-grey-dark;
      display: inline-block;
      font-size: .875em;
      margin-right: $spacing;
    }
  }
}
