

.resource {
  background: $color-white;
  padding: $spacing-fluid $spacing-fluid 0;
  position: relative;

  &__header {
    width: 100%;
  }

  &__header,
  &__main,
  &__aside {
    margin-bottom: $spacing-fluid / 2;
  }

  &__footer {
    border-top: $border-width solid $border-color;
    flex: 1 1 auto;
    margin: 0 (-$spacing-fluid);
    padding: $spacing $spacing-fluid;
  }

  @media only screen and (min-width: map-get($bp, desktop)) {

    &__inner {
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-$spacing-fluid / 2);
    }

    &__header {
      margin: 0;
      padding: 0 ($spacing-fluid / 2) $spacing-fluid;
    }

    &__main,
    &__aside {
      padding: 0 ($spacing-fluid / 2);
    }

    &__main {
      width: 66.66%;
    }

    &__aside {
      width: 33.33%;
    }

    &__footer {
      margin: 0 (-$spacing-fluid / 2);
    }
  }

  @media only screen and (min-width: map-get($bp, max)) {
    padding: $spacing-lg $spacing-xl 0;

    &__inner {
      margin: 0 (-$spacing-xl / 2);
    }

    &__header {
      padding: 0 ($spacing-xl / 2) $spacing-lg;
    }

    &__main,
    &__aside {
      padding: 0 ($spacing-xl / 2);
    }

    &__footer {
      margin: 0 (-$spacing-xl / 2);
      padding: $spacing $spacing-xl;
    }
  }
}
