

.topic-summary {
  display: flex;
  margin: (-$spacing-sm / 2) (-$spacing-sm / 2);

  &__letter,
  &__content {
    padding: $spacing-fluid-sm 0;
  }

  &__letter {
    padding-left: $spacing-xs;
    padding-right: $spacing;
    position: relative;
    top: .05em;

    @media screen and (min-width: map-get($bp, tablet)) {
      font-size: $h3-font-size;
    }
  }

  &__title {
    margin-bottom: $spacing-sm;

    @media screen and (min-width: map-get($bp, tablet)) {
      font-size: $h2-font-size;
    }
  }

  &__content {
    flex: 1 1 auto;
    min-width: 0;
  }

  &__meta {
    font-size: $font-size-sm;
  }

  &__completed {
    color: $color-success;
    margin: 0;

    .icon {
      font-size: 1.25em;
      vertical-align: -.1em;
    }
  }

  &__progress {
    margin-top: $spacing-xs;
  }
}
