

.note-book {
  background: $color-brand-primary-orange;

  &__content {
    display: flex;
    min-height: 11em;

    @media screen and (min-width: map-get($bp, desktop)) {
      min-height: 19em;
    }
  }

  &__link {
    background: transparent;
    display: block;
    padding: $spacing $spacing;
    transition: background $transition-duration $transition-timing;
    width: 100%;

    .note-book--loading & {
      background: $color-brand-grey;
      pointer-events: none;
    }

    &:hover {
      background: $color-darken;

      .note-book__subject {
        padding-left: ($spacing-lg * 1.125);
      }
    }

    @media screen and (min-width: map-get($bp, desktop)) {
      padding: $spacing-lg $spacing-lg;
    }
  }

  &__subject {
    display: inline-block;
    margin: 0 0 $spacing (-$spacing);
    padding: $spacing-sm $spacing-lg $spacing-sm $spacing;
    transition: padding $transition-duration $transition-timing;

    @media screen and (min-width: map-get($bp, desktop)) {
      margin: 0 0 $spacing (-$spacing-lg);
      padding: $spacing $spacing-xl $spacing $spacing-lg;
    }
  }

  &__title {
    font-size: 1.5em;
    font-weight: 300;

    @media screen and (min-width: map-get($bp, desktop)) {
      font-size: 2em;
    }
  }

  &__notes {
    font-size: $font-size-lg;
  }

  &__title,
  &__notes {
    color: $color-white;
    transition: opacity $transition-duration $transition-timing;

    .note-book--loading & {
      opacity: 0;
    }
  }

  &__action {

  }

  &__export {
    appearance: none;
    background: $color-darken-more;
    border: 0 none;
    color: $color-white;
    cursor: pointer;
    display: block;
    padding: $spacing $spacing;
    text-align: left;
    transition: background $transition-duration $transition-timing, opacity $transition-duration $transition-timing;
    width: 100%;

    &:hover {
      background: shade($color-darken-more, 10);
    }

    &[disabled] {
      pointer-events: none;
    }

    .note-book--loading & {
      opacity: 0;
    }
  }

  &__content-wrap {
    width: 100%;
  }

  &__blank-block {
    background: transparentize($color-brand-grey-dark, .6);
    height: $spacing;
    margin-bottom: $spacing-sm;

    &--half {
      width: 50%;
    }
  }

  &--loading {
    background: $color-brand-grey;
    opacity: .5;
  }

  &--error {

  }

  &--empty {
    background: $color-brand-grey-light;
  }

  &--blank {
    .note-book__content {
      min-height: 11em;
      padding: $spacing $spacing;
      @media screen and (min-width: map-get($bp, desktop)) {
        padding: $spacing-lg $spacing-lg;
        min-height: 15em;
      }
    }

    .note-book__subject {
      background: $color-brand-secondary-orange;
    }

    .note-book__action {
      background: transparentize($color-brand-grey-dark, .6);
      height: $spacing-lg;
      width: 100%;
    }
  }
}
