
.progress-badge {
  color: $color-brand-primary;
  display: inline-block;
  // padding: $spacing-sm $spacing-sm;
  position: relative;

  &::before {
    $positioning-vertical: 4.5%; // calc(#{$spacing-sm} + 4.5%);
    $positioning-horizontal: 4.5%; // calc(#{$spacing-sm} + 4.5%);
    background: currentColor;
    border-radius: 50%;
    bottom: $positioning-vertical;
    content: '';
    display: block;
    left: $positioning-horizontal;
    position: absolute;
    right: $positioning-horizontal;
    top: $positioning-vertical;
  }

  &--brand-primary {
    color: $color-brand-primary;
  }

  &--blue-dark {
    color: $color-blue-dark;
  }

  &--green-dark {
    color: $color-green-dark;
  }

  &--green {
    color: $color-green;
  }

  &--orange-dark {
    color: $color-orange-dark;
  }

  &--white {
    color: $color-white;
  }

  &--completed {
    &::before {
      background: none;
    }

    &[style*="width: 125px;"] {
      .progress-badge__icon {
        font-size: 8em;
      }

      .progress-badge__value,
      .progress-badge__text {
        font-size: 2.34em;
      }
    }

    .progress-badge__content {
      display: flex;
      height: 100%;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    .progress-badge__icon {
      font-size: 85 / 14 * 1em;
      margin: 0;
      position: absolute;

      @media screen and (min-width: map-get($bp, md)) {
        font-size: 85 / 16 * 1em;
      }
    }

    .progress-badge__value,
    .progress-badge__text {
      color: $color-white;
      display: inline-block;
      font-size: 25.5 / 16 * 1em;
      left: 50%;
      margin: auto;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
