

.notes-nav {
  &__subjects {
    list-style-type: none;
    margin: 0 $spacing-root / 2;
  }

  &__subject {
    &-link {
      color: $color-brand-purple;
      display: block;
      overflow: hidden;
      padding: $spacing-md $spacing-root / 2;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover &-link {
      background: tint($color-darken, 10);
    }

    &--active {
      background: $color-darken;

      .notes-nav__subject-link {
        color: $body-color;
      }
    }
  }

  @media screen and (min-width: map-get($bp, desktop)) {
    &__subjects {
      list-style-type: none;
      margin: 0 $spacing-lg / 2;
    }

    &__subject-link {
      padding: $spacing-md $spacing-lg / 2;
    }
  }
}
