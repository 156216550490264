

.course {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: map-get($bp, md)) {
    flex-direction: row;
  }

  &__image {
    background: $color-black;
    background-image: url('/images/pages/guides/bg-course-artwork-professional-2019.jpg');
    background-position: center;
    background-size: cover;
    min-width: 10em;

    &::before {
      content: '';
      display: block;
      padding-top: 25%;

      @media screen and (min-width: map-get($bp, md)) {
        min-width: 15em;

        &::before {
          padding-top: 66.6666%;
        }
      }
    }

    &--brand-primary {
      background-color: $color-brand-primary;
      background-image: url('/images/pages/guides/bg-course-artwork-professional-2015.png');
    }

    &--blue-dark {
      background-color: $color-blue-dark;
      background-image: url('/images/pages/guides/bg-course-artwork-certificate-2017.png');
    }
  }

  &__eyebrow {
    margin-bottom: 0;
  }

  &__header {
    margin-bottom: $spacing;
  }

  &__status {
    color: $color-white;
    display: inline-block;
    float: right;
    font-size: $font-size-sm;
    margin-right: -($spacing / $font-size-sm * 1em);
    padding: $spacing-xs $spacing;

    @media screen and (min-width: map-get($bp, desktop)) {
      margin-right: -($spacing-lg / $font-size-sm * 1em);
    }

    &--passed {
      background: $color-green;
    }

    &--studying {
      background: $color-orange;
    }
  }

  &__content {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    padding: $spacing $spacing;

    @media screen and (min-width: map-get($bp, desktop)) {
      padding: $spacing-lg $spacing-lg;
    }
  }

  &__description {
    flex: 1 0 auto;
    margin-bottom: $spacing;

    p:last-child {
      margin: 0;
    }
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    font-size: $font-size-sm;
    list-style-type: none;
    margin: (-$spacing-sm) (-$spacing-sm);
    padding: 0;

    &-item {
      padding: $spacing-sm $spacing-sm;
      white-space: nowrap;
    }
  }
}
