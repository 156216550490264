// Variables
//
// Variable format should follow the $[element]-[property]-[orientation]-[size] order.


// Table of contents
//
// Global
// -- Colors
// -- Borders
// -- Border radius
// -- Breakpoints
// -- Fonts
// -- Spacing
// -- Transitions
// Element
// -- Body
// -- Headings
// -- Inputs
// -- Labels
// -- Links
// -- Tables


// Colors
// ==================================================

@import 'palette';


// Borders
// ==================================================

$border-color: $color-grey-lighter;
$border-style: solid;

$border-width: 1px;
$border-width-sm: $border-width;
$border-width-lg: $border-width * 2;

$border: $border-style $border-width $border-color;


// Border radius
// ==================================================

$border-radius: .125em;


// Breakpoints
// ==================================================
// Usage: @media screen and (min-width: map-get($bp, sm)) {}

$container-max-width: 1200 / 16 * 1em;

$bp: (
  xs: 0,
  sm: 28em,
  md: 38.4em,
  lg: 48em,
  xl: 55em,
  xxl: 60em,
  xxxl: 70em,
  max: $container-max-width,
  tablet: 48em,
  desktop: 60em // convenience bp
);

// Fonts
// ==================================================

$font-family-cursive: cursive;
$font-family-monospace: monospace;
$font-family-sans-serif: "Roboto", sans-serif;
$font-family-serif: "Georgia", "Times", "Times New Roman", serif;

$font-family: $font-family-sans-serif;

$line-height: 1.5;
$font-size-small-screen: .875rem;
$font-size-base: 1rem; // 16px
$font-size-xs: .75em;
$font-size-sm: .875em;
$font-size-md: 1.125em;
$font-size-lg: 1.25em;
$font-size-xl: 1.5em;

$selection-bg: $color-darken;
$selection-color: inherit;

/* $icomoon-font-path: '../public/fonts/icons/fonts'; */


// Spacing
// ==================================================

$spacing: 1em; // 16px
$spacing-lg: $spacing * 2;
$spacing-md: $spacing * .75;
$spacing-sm: $spacing / 2;
$spacing-xl: $spacing * 4;
$spacing-xs: $spacing / 4;

$spacing-fluid: 5%;
$spacing-fluid-lg: $spacing-fluid * 1.5;
$spacing-fluid-sm: $spacing-fluid * .5;

$spacing-root: 5vw;
$spacing-root-lg: $spacing-root * 1.5;
$spacing-root-sm: $spacing-root * .5;
$spacing-root-xl: $spacing-root * 2.25;

$spacing-thumb: 3rem;

// Transitions
// ==================================================

$transition-duration: 200ms;
$transition-timing: ease-in-out;

// Boingy transitions
$transition-duration-boing: .3s;
$transition-timing-boing: cubic-bezier(.84, .05, .46, 1.78);


// Body
// ==================================================

$body-bg: $color-grey-lighter;
$body-color: $color-grey-darker;


// Headings
// ==================================================

$h1-font-size: 2.25em;
$h2-font-size: 1.75em;
$h3-font-size: 1.25em;
$h4-font-size: 1.125em;
$h5-font-size: 1em;
$h6-font-size: .875em;

$heading-color: $color-black;
$heading-font-family: $font-family-sans-serif;
$heading-font-weight: 400;
$heading-font-weight-light: 300;
$heading-line-height: 1.25;

// Layout
// ==================================================

$max-z-index: 10000;

// Inputs
// ==================================================

$input-bg: $color-white;
$input-bg-disabled: $color-grey-lightest;

$input-border-color: $color-grey-lighter;
$input-border-color-focus: $color-grey-light;
$input-border-color-hover: $color-grey-light;

$input-border-radius: $border-radius;

$input-box-shadow: none;

$input-color: $body-color;
$input-color-placeholder: $color-grey;

$input-padding-horizontal: $spacing-sm;
$input-padding-vertical: $spacing-sm;


// Labels
// ==================================================

$label-color: inherit;
$label-font-size: 1rem;


// Buttons
// ==================================================

$button-bg: $color-brand-primary;
$button-color: $color-white;
$button-border-width: .125em;
$button-line-height: 1.5;

// Links
// ==================================================

$link-color: $color-brand-primary;
$link-active-color: $link-color;
$link-focus-color: shade($color-brand-primary, 10%);
$link-hover-color: $link-focus-color;

$link-decoration: underline;
$link-hover-decoration: none;


// Tables
// ==================================================

$table-cell-padding: $spacing-sm $spacing-sm;

$table-bg: transparent;

$table-border-color: $border-color;
$table-border-width: $border-width;

$table-border-collapse: true;
$table-layout-fixed: false; // True breaks styleguide


// Tab bar
// ==================================================

$tab-bar-height: $spacing * 3;
