.audio-preview {
  display: inline-block;
  position: relative;

  &__progress {
    background: $color-grey-lighter;
    bottom: $border-width-lg;
    left: $border-width-lg;
    height: .25rem;
    opacity: 0;
    position: absolute;
    right: $border-width-lg;
    transition: opacity $transition-duration $transition-timing;

    &-bar {
      background: $color-brand-primary;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: var(--audio-preview-progress-width, 0);
    }
  }

  &--playing {
    .audio-preview__progress {
      opacity: 1;
    }
  }
}
