

@keyframes pulse {
  0% { box-shadow:0 0 8px $color-brand-purple, inset 0 0 8px $color-brand-purple; }
  50% { box-shadow:0 0 16px $color-brand-purple, inset 0 0 14px $color-brand-purple; }
  100% { box-shadow:0 0 8px $color-brand-purple, inset 0 0 8px $color-brand-purple; }
}

.status-indicator {
  animation: pulse 2s linear 1s infinite;
  background: $color-brand-purple;
  border-radius: 50%;
  box-shadow: 0 0 8px $color-brand-purple, inset 0 0 8px $color-brand-purple;
  width: 15px;
  height: 15px;

  position: absolute;
  top: -5px;
  left: -5px;
}
