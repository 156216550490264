

$app-link-border-width: .25em;

.app-nav {
  background: $color-white;
  position: sticky;
  top: 0;
  z-index: $max-z-index;

  &__inner {
    bottom: 0;
    display: flex;
    margin: auto;
    max-width: $container-max-width;
    position: fixed;
    width: 100%;
    z-index: 10;

    @media screen and (min-width: map-get($bp, desktop)) {
      align-items: center;
      bottom: auto;
      position: sticky;
      top: 0;
    }
  }

  &__logo {
    display: none;
    margin-right: $spacing;
    padding: 0 0 .25em $spacing-sm;

    @media screen and (min-width: map-get($bp, desktop)) {
      display: block;
    }
  }

  &__user {
    .icon {
      margin: 0 0 0 $spacing-xs;
    }

    .app-nav__link {
      margin-right: 0;
    }
  }

  &__list {
    align-self: flex-start;
    background: $color-white;
    border-top: $border-width solid $border-color;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 2;

    @media screen and (min-width: map-get($bp, desktop)) {
      border: 0 none;
      width: auto;
    }

    &--drawer {
      border-top: 0 none;
      display: block;
      position: absolute;
      text-align: left;
      top: 0;
      transform: none;
      transition: transform $transition-duration $transition-timing;
      width: 100%;
      z-index: 1;

      .app-nav--open & {
        transform: translateY(-100%);
      }

      @media screen and (min-width: map-get($bp, desktop)) {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row-reverse;
        position: static;
        text-align: center;
        width: auto;

        .app-nav--open & {
          transform: none;
        }
      }
    }
  }

  &__item {
    flex: 1 1 auto;

    @media screen and (min-width: map-get($bp, desktop)) {
      flex: 0 0 auto;

      &--more,
      &--logout {
        display: none;
      }

      &--settings {
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;
      }
    }
  }

  &__link {
    color: inherit;
    display: block;
    font-size: $font-size-sm;
    overflow: hidden;
    padding: $spacing-sm $spacing-sm;
    position: relative;
    text-decoration: none;

    &::before {
      background: $color-brand-primary;
      content: '';
      height: .25em;
      left: 0;
      position: absolute;
      top: 0;
      transition: transform $transition-duration $transition-timing;
      transform: translateY(-100%);
      width: 100%;
    }

    &:hover,
    &:focus-visible {
      background-color: transparentize($color-brand-primary, 0.975);
      color: $link-color;
    }

    &:active {
      background-color: transparentize($color-brand-primary, 0.975);
    }

    &--active {
      border-color: $color-brand-purple;

      &::before {
        transform: none;
      }
    }

    .icon {
      color: $color-grey-dark;
      display: block;
      font-size: $font-size-xl;
    }

    .app-nav__list--drawer & {
      align-items: center;
      border-top: $border-color solid $border-width;
      display: flex;
      gap: $spacing;
      margin: 0;
      padding: $spacing $spacing;

      &::before {
        height: 100%;
        transform: translateX(-100%);
        width: .25em;
      }

      &--active::before {
        transform: none;
      }
    }

    @media screen and (min-width: map-get($bp, desktop)) {
      border: 0 none;
      padding: $spacing $spacing $spacing-sm;

      .app-nav__list--drawer & {
        border: 0 none;
        display: block;
        padding: $spacing $spacing $spacing-sm;

        &::before {
          height: .25em;
          transform: translateY(-100%);
          width: 100%;
        }

        &--active::before {
          transform: none;
        }
      }
    }
  }
}
