
.onboarding-content {
  &__breadcrumb {
    counter-reset: breadcrumb-counter;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &-item {
      color: $color-grey-dark;
      counter-increment: breadcrumb-counter;
      flex-grow: 1;
      text-align: center;
      width: 33.33%;

      &::before {
        content: counter(breadcrumb-counter) '.';
        display: inline-block;
        margin-right: $spacing-sm;
      }

      &--active {
        color: $color-grey-darker;
      }
    }

    &-item--complete {
      color: $color-green-dark;

      .onboarding-content__breadcrumb-icon {
        display: inline-block;
      }

      &::before {
        display: none;
      }
    }

    &-icon {
      display: none;
      margin-right: $spacing-sm;
    }
  }

  &__inner {
    padding: $spacing-fluid 0;
    text-align: center;
  }

  &__image {
    margin: auto;
    max-width: 20em;
    width: 60%;
  }

  &__src {
    display: block;
    margin: auto;
    max-width: 100%;
  }

  &__progress {
    margin: auto;
    max-width: 24em;
  }

  &__title {
    color: $color-brand-primary;
    margin: $spacing-sm 0;

    @media screen and (min-width: map-get($bp, 'lg')) {
      font-size: $h2-font-size;
    }
  }

  &__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 31em;

    @media screen and (min-width: map-get($bp, 'lg')) {
      font-size: $font-size-md;
    }
  }

  &__strapline {
    color: $color-grey-dark;
    font-size: .875em;
    padding-top: $spacing;
  }
}
