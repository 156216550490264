

.resource-image {
  background: $color-grey-lightest;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
    max-height: 50vh;
    max-width: 100%;
    mix-blend-mode: multiply;
    width: auto;
  }
}
