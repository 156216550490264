html {
  background: $body-bg;
  box-sizing: border-box;
  min-height: 100vh;
  overflow-x: hidden;
  overscroll-behavior-y: none;
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

body {
  min-height: 100vh;
  min-width: 20em;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next,
.app {
  display: flex;
  flex-direction: column;
  height:100%;
  min-height: 100vh;
  min-width: 100%;
  width:100%;

  /* stylelint-disable-next-line no-descending-specificity */
  > * {
    width: 100%;
  }
}
