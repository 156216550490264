


.study-time-content {
  background: $color-white;
  width: 100%;

  &__summary {
    @include clearfix;
    border-bottom: $border-width $border-style $border-color;
    padding: $spacing-lg 0;

    &-icon {
      float: left;
      font-size: 2em;
      opacity: .25;
    }

    &-content {
      margin-left: 2.5em;
      overflow: hidden;
    }

    &-title {
      font-weight: 700;
    }

    &-text {
      font-size: $font-size-sm;
      margin: 0;

      &--error {
        color: $color-danger;
      }

      &--warning {
        color: $color-info;
      }
    }
  }

}
