

.topic-letter {
  $topic-letter-border-width: 2px;
  $topic-letter-border-width-small: $topic-letter-border-width / 2;
  $topic-letter-border-radius: $topic-letter-border-width;
  $topic-letter-border-radius-small: $topic-letter-border-width-small;
  $topic-letter-border-color: currentColor;
  $topic-letter-border: $topic-letter-border-width solid $topic-letter-border-color;
  $topic-letter-border-small: $topic-letter-border-width-small solid $topic-letter-border-color;

  border: $topic-letter-border;
  border-radius: $topic-letter-border-radius;
  border-right-width: 0;
  border-top-width: 0;
  color: inherit;
  display: inline-block;
  font-size: 2rem;
  padding: .125em;
  position: relative;
  vertical-align: middle;

  + .topic-letter {
    margin-left: $spacing-sm;
  }

  &::before {
    border-top: $topic-letter-border;
    content: '';
    left: 0;
    position: absolute;
    right: .375em;
    top: 0;
  }

  &::after {
    border-right: $topic-letter-border;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: .375em;
  }

  &[title] {
    cursor: help;
    position: relative;
    z-index: 1;
  }

  &__inner {
    line-height: 1.5;
    text-align: center;
    width: 1em;

    &::before {
      border-top: $topic-letter-border;
      content: '';
      position: absolute;
      right: -$topic-letter-border-width * 2;
      top: 0;
      transform: rotate(45deg);
      transform-origin: top left;
      width: .5em;

    }
  }

  &__text {
    display: inline-block;
    line-height: 1.25;
    position: relative;
    right: $topic-letter-border-width / 2;
    text-align: center;
    top: $topic-letter-border-width;
    width: .85em;
  }

  // ------- Themes

  &--brand-primary {
    color: $color-brand-primary;
  }

  &--blue-dark {
    color: $color-blue-dark;
  }

  &--green-dark {
    color: $color-green-dark;
  }

  &--green {
    color: $color-green;
  }

  &--orange-dark {
    color: $color-orange-dark;
  }

  &--white {
    color: $color-white;
  }

  // ------- Sizes

  &--medium {
    font-size: 1.5em;
    padding-bottom: $topic-letter-border-width;
  }

  &--small {
    font-size: 1em;
  }

  &--extra-small {
    font-size: $font-size-sm;
  }

  &--small,
  &--extra-small {
    border-bottom: $topic-letter-border-small;
    border-left: $topic-letter-border-small;
    border-radius: $topic-letter-border-radius-small;
    padding-bottom: 0;

    &::before {
      border-top: $topic-letter-border-small;
    }

    &::after {
      border-right: $topic-letter-border-small;
    }

    .topic-letter__inner  {

      &::before {
        border-top: $topic-letter-border-small;
        right: -$topic-letter-border-width-small * 2;
      }
    }

    .topic-letter__text {
      right: 0;
      top: 0;
    }
  }
}
