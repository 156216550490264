

.login-button {
  color: inherit;
  font-size: $font-size-sm;
  text-decoration: none;

  .icon {
    font-size: $font-size-lg;
    margin-left: $spacing-xs;
    vertical-align: middle;
  }
}
