

.exam-status {
  padding: $spacing $spacing-fluid;

  + .exam-status {
    border-top: $border-width $border-style $color-darken;
  }

  &:last-child {
    border-bottom: $border-width $border-style $color-darken;
  }

  &__icon {
    color: $color-brand-primary;
    float: left;
    font-size: 1.5em;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__title {
    font-weight: bold;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }

  &__subtitle {
    color: $color-grey-dark;
    font-size: $font-size-sm;
    margin: 0;
    overflow: hidden;
    padding: $spacing-sm 0 0;
  }

  &__title,
  &__subtitle {
    margin-left: 2rem;
  }

  &__action {
    color: $color-grey-dark;
    margin-left: 2rem;
    min-width: 8em;
    padding-top: $spacing-sm;

    label {
      color: $color-grey;
      font-size: $font-size-sm;
      font-weight: normal;
    }
  }

  &__status {
    font-weight: 700;
  }

  &__select {
    margin: 0 0 $spacing-xs;
    min-width: 10em;
  }

  &__note {
    color: $color-grey-dark;
    font-size: $font-size-sm;
    margin: 0;
  }

  @media screen and (min-width: map-get($bp, md)) {
    align-items: center;
    display: flex;

    &__content {
      flex-grow: 1;
    }

    &__action {
      flex-shrink: 1;
      margin-left: $spacing;
      padding-top: 0;
    }
  }

  // Always Horizontal
  &--horizontal {
    align-items: center;
    display: flex;

    &__content {
      flex-grow: 1;
    }

    &__action {
      flex-shrink: 1;
      padding-top: 0;
    }
  }

  // Status
  &--passed {

    .exam-status__status {
      color: $color-green-dark;
    }
  }

  &--studying,
  &--preparing,
  &--booked {
    .exam-status__status {
      color: $color-orange;
    }
  }

  // Color theming

  &--blue-dark {
    .exam-status__icon {
      color: $color-blue-dark;
    }
  }

  &--green-dark {
    .exam-status__icon {
      color: $color-green-dark;
    }
  }

  &--green {
    .exam-status__icon {
      color: $color-green;
    }
  }

  &--orange-dark {
    .exam-status__icon {
      color: $color-orange-dark;
    }
  }
}
