.unstyled-a {
  all: inherit;
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}
