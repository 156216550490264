


.session-status {
  align-items: center;
  background: $color-grey-lightest;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  overflow: hidden;
  padding: $spacing $spacing;
  position: relative;
  user-select: none;

  &::before {
    background: $color-success;
    border-radius: 50%;
    content: '';
    left: $spacing + .5em;
    padding-top: 200%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform $transition-duration $transition-timing;
    width: 200%;
    z-index: 0;
  }

  &__indicator {
    display: inline-block;
    height: 1em;
    margin-right: $spacing-sm;
    position: relative;
    width: 1em;
  }

  input {
    @include sr-only;
  }

  &__checkbox {
    background: $color-white;
    border: 1px solid $color-brand-grey-dark;
    display: inline-block;
    height: 1em;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform $transition-duration $transition-timing, opacity $transition-duration $transition-timing;
    width: 1em;
  }

  &__checked {
    background: $color-white;
    border-radius: 50%;
    color: $color-success;
    height: 1.25em;
    left: 50%;
    line-height: 1.25;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform $transition-duration $transition-timing, opacity $transition-duration $transition-timing;
    width: 1.25em;

    .icon {
      line-height: 1.25;
    }
  }

  &__text {
    flex: 1 1 auto;
    position: relative;

    &-default {
      white-space: nowrap;
    }

    &-completed {
      color: $color-white;
      display: none;
      left: 0;
      position: absolute;
      white-space: nowrap;
    }
  }

  &__hours {
    position: relative;
  }

  &--completed {
    &::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .session-status__checkbox {
      opacity: 0;
      transform: scale(0);
    }

    .session-status__checked {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    .session-status__text {
      &-default {
        opacity: 0;
      }

      &-completed {
        display: inline;
      }
    }

    .session-status__hours {
      color: $color-white;
    }
  }
}
