body {
  -webkit-font-smoothing: antialiased;
  color: $body-color;
  font-family: $font-family;
  font-size: $font-size-small-screen;
  line-height: $line-height;

  @media screen and (min-width: map-get($bp, lg)) {
    font-size: $font-size-base;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // color: $heading-color; Just inherit
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  margin: 0;
}

h1 {
  font-size: $h1-font-size;
  font-weight: $heading-font-weight-light;
}

h2 {
  font-size: $h2-font-size;
  font-weight: $heading-font-weight-light;
}

h3 {
  font-size: $h3-font-size;
  @media screen and (min-width: map-get($bp, md)) {
    font-weight: $heading-font-weight-light;
  }
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

p {
  margin: 0 0 $spacing;
}

a {
  color: $link-color;
  cursor: pointer;
  text-decoration: none;

  p & {
    text-decoration: $link-decoration;

    &:hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }

    &:focus {
      color: $link-focus-color;
    }

    &:active {
      color: $link-active-color;
    }
  }
}

::selection {
  background: $selection-bg;
  color: $selection-color;
  text-shadow: none;
}

.muted {
  color: $color-darken-lots; // @TODO should be a component?
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  transition: opacity $transition-duration $transition-timing;
}

// @TODO put this back in when they have fixed the next links bug
// https://github.com/zeit/next.js/issues/7915
// .js {
//   &:not(.wf-active):not(.wf-inactive):not(.wf-slow) {
//     h1,
//     h2,
//     h3,
//     h4,
//     h5,
//     h6,
//     p,
//     span,
//     a {
//       opacity: 0;
//     }
//   }
// }

hr {
  border: 1px solid $color-grey-lighter;
  border-width: 1px 0 0 0;
}

