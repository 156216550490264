.video-summary {
  &__image {
    display: block;
  }

  &__content {
    padding: $spacing $spacing;
  }

  &__title {
    font-size: 1em;
    font-weight: 700;
  }

  &__description {
    font-size: $font-size-sm;
    margin: 0;
  }
}
