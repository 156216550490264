


.focus-list {
  border-top: $border-width solid $color-darken;
  list-style-type: none;
  margin: 0;
  padding: 0;

  &__item {
    border-bottom: $border-width solid $color-darken;

    &--completed {
      .focus-list__meta {
        color: $color-green-dark;
      }

      input:checked ~ .focus-list__status .focus-list__icon,
      .focus-list__icon {
        background: $color-green-dark;
      }
    }

    &--passed {
      .focus-list__label {
        cursor: default;
      }

      .focus-list__meta {
        color: $color-green-dark;
      }

      .focus-list__icon {
        background: none;
        top: .125em;
      }
    }
  }

  &__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    margin: 0;
    padding: $spacing-sm $spacing-fluid;

    input {
      @include sr-only;
    }
  }

  &__folder {
  }

  &__content {
    flex-grow: 1;
    padding: $spacing $spacing;
  }

  &__title {
    font-weight: bold;
  }

  &__meta {
    font-size: $font-size-sm;
    margin: 0;
  }

  &__status {
    text-align: right;

    input:checked ~ & {
      .focus-list__icon {
        background: $color-orange;
      }
    }

    &-text {
      display: none;
      font-size: .75em;
      margin-right: $spacing-sm;
      opacity: .5;
      vertical-align: text-top;

      @media screen and (min-width: map-get($bp, md)) {
        display: inline-block;
      }
    }

    @media screen and (min-width: map-get($bp, md)) {
      min-width: 12ch;
    }
  }

  &__icon {
    background: $color-grey;
    border-radius: 50%;
    color: $color-white;
    height: 1.3em;
    margin: 0;
    padding: .2em;
    position: relative;
    width: auto;
    z-index: 1;

    &-wrap {
      position: relative;
    }
  }

  &__badge {
    color: $color-green-dark;
    font-size: 1.4em;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
