

.state-message {
  clear: both; // Clear close button in modals
  text-align: center;

  &__icon {
    display: block;
    font-size: 3em;
  }

  &__content {
    color: $body-color;
  }

  // Types

  &--info {
    color: $color-info;
  }

  &--warning {
    color: $color-warning;
  }

  &--success {
    color: $color-success;
  }

  &--danger {
    color: $color-danger;
  }
}
