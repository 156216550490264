

@keyframes loading {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

@keyframes loading-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.loading {
  animation: loading-fade .4s ease-in-out 1 .5s backwards;
  padding: $spacing-fluid $spacing-fluid;
  text-align: center;
  width: 100%;

  &__img {
    animation: loading 1.5s ease-in-out infinite;
    display: block;
    height: 3em;
    margin: auto;
    opacity: .125;
    width: 3em;
  }

  &--block {
    background: $color-grey-lightest;

    + & {
      margin-top: $spacing;
    }
  }
}
