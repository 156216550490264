

.context-header {
  // Make this 100% width always even in a container
  margin-left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;

  &--logged-in {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 10;

    + .context-header__offset {
      padding-top: ($spacing * 2) + (1em * $line-height);

      @media screen and (min-width: map-get($bp, max)) {
        margin-bottom: $spacing-lg;
      }
    }
  }
}
