

.login {
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (min-width: map-get($bp, tablet)) {
    margin-top: $spacing-fluid-sm;
    min-height: 60vh;
  }

  &__content {
    max-width: 50em;
    width: 100%;

    @media screen and (min-width: map-get($bp, desktop)) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__alert {
    flex: 1 1 100%;
  }

  &__main,
  &__aside {
    padding: $spacing-fluid $spacing-fluid;

    @media screen and (min-width: map-get($bp, desktop)) {
      flex: 1 1 50%;
      max-width: 50%;
      width: 50%;
    }

    p:last-child {
      margin: 0;
    }
  }

  &__main {
    background: $color-white;

    @media screen and (min-width: map-get($bp, desktop)) {
      margin: 0;
    }

    .button {
      margin-top: $spacing;
      min-width: 10em;
    }
  }

  &__aside {
    background: $color-brand-primary;
    color: $color-white;

    a {
      color: inherit;

      &:hover,
      &:focus {
        color: $color-white;
      }
    }

    h3 {
      margin-bottom: $spacing-xs;
    }
  }
}
