

.progress-bar {
  background: $color-grey-lighter;
  height: .5em;
  position: relative;

  &__value {
    background: $color-green-dark;
    bottom: 0;
    display: block;
    left: 0;
    position: absolute;
    top: 0;
  }
}
