

.exam-dates {
  margin-bottom: $spacing-lg;

  &__item {
    background: $color-white;
    border: 1px solid transparent;
    border-color: $border-color;
    display: flex;
    padding: $spacing-sm $spacing;
    flex-direction: column;
    margin-bottom: $spacing-sm;
    width: 100%;

    &-title {
      font-weight: bold;
    }

    &-link {
      margin-top: auto;
    }

    &-dates {
      margin-bottom: $spacing;
    }

    &-date {
      display: flex;
      width: 100%;

      &-label {
        display: block;
        min-width: 3em;
      }
    }

    &--muted {
      background: $color-grey-lightest;
    }
  }

  @media screen and (min-width: map-get($bp, tablet)) {
    display: flex;
    margin: 0 (-$spacing-sm) $spacing-lg;

    &__item {
      width: 33.3333333%;
      margin: 0 $spacing-sm;

      &-content,
      &-link {
        font-size: $font-size-sm;
      }
    }
  }
}
