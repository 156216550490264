

.case-study-status {
  margin-bottom: $spacing;

  &__title {
    margin-bottom: $spacing-sm;
  }

  &__set-date {
    display: flex;
    justify-content: space-between;
  }

  &__button button {
    color: $color-brand-purple;
  }
}
