

.notice-sidebar {
  background: $color-grey-lightest;
  color: $body-color;
  display: block;
  text-decoration: none;

  @media screen and (min-width: map-get($bp, lg)) {
    align-items: center;
    display: flex;
  }

  @media screen and (min-width: map-get($bp, desktop)) {
    display: block;
  }

  &__image {
    display: none;

    &-src {
      display: block;
      height: auto;
      width: 100%;
    }

    @media screen and (min-width: map-get($bp, lg)) {
      display: block;
      max-width: 12em;
    }

    @media screen and (min-width: map-get($bp, desktop)) {
      max-width: none;
    }
  }

  &__content {
    padding: $spacing $spacing;
  }

  &__header {
    margin-bottom: $spacing-sm;
  }

  &__title {
    color: $color-brand-primary;
    font-size: $h3-font-size;
  }

  &__cta {
    color: $color-brand-primary;
    display: block;
    font-size: $font-size-sm;
    font-weight: normal;

    &-text {
      font-weight: bold;

      &:hover {
        color: $color-grey-darker;
        text-decoration: underline;
      }
    }

    &-icon {
      margin-left: $spacing-xs;
    }
  }

  // Themes

  &--black {
    background: $color-brand-black;
    color: $color-white;

    .notice-sidebar__title,
    .notice-sidebar__cta {
      color: inherit;
    }

    .notice-sidebar__cta-text:hover {
      color: inherit;
    }
  }

  &--blue {
    background: $color-white;

    .notice-sidebar__title,
    .notice-sidebar__cta {
      color: $color-brand-primary-blue;
    }
  }

  &--purple {
    background: $color-brand-purple;

    .notice-sidebar__title,
    .notice-sidebar__cta,
    .notice-sidebar__content {
      color: $color-white;
    }

    .notice-sidebar__cta-text:hover {
      color: inherit;
    }
  }

  &--banner {
    .notice-sidebar__image {
      display: block;

      img {
        aspect-ratio: 16 / 9;
      }
    }

    .notice-sidebar__title {
      font-size: $h2-font-size;
    }

    @media screen and (min-width: map-get($bp, lg)) {
      align-items: stretch;
      flex-direction: row-reverse;

      .notice-sidebar__image,
      .notice-sidebar__content {
        flex-basis: 50%;
      }

      .notice-sidebar__image {
        display: grid;
        max-width: none;

        img {
          object-fit: cover;
          object-position: center right;
        }
      }

      .notice-sidebar__content {
        align-self: center;
      }
    }

    @media screen and (min-width: map-get($bp, desktop)) {
      display: flex;
    }
  }
}
