

.exam-windows {
  margin-bottom: $spacing-lg;

  &__icon {
    font-size: 2.125em;
    line-height: 1;
    margin-right: $spacing-xs;
  }

  &__button {
    color: $color-brand-purple;
  }

  &__window {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-weight: bold;
    }

    &-icon {
      font-size: 2.125em;
      line-height: 1;
      margin-right: $spacing-xs;
    }

    &-content {
      flex-grow: 1;
    }
  }

  &__list + &__list {
    margin-top: $spacing-lg;
  }

  &--current &__window {
    justify-content: flex-start;

    .exam-windows__window-meta {
      display: flex;
      justify-content: space-between;
    }

    .exam-windows__window-meta-info {
      display: none;
    }

    @media screen and (min-width: map-get($bp, tablet)) {
      .exam-windows__window-meta-info {
        display: inline-block;
      }
    }
  }

  .list__item {
    margin-bottom: $spacing-xs;
  }
}
