@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2m09q9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2m09q9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2m09q9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-th-list {
  &:before {
    content: $icon-th-list; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-times {
  &:before {
    content: $icon-times; 
  }
}
.icon-search-plus {
  &:before {
    content: $icon-search-plus; 
  }
}
.icon-search-minus {
  &:before {
    content: $icon-search-minus; 
  }
}
.icon-cog {
  &:before {
    content: $icon-cog; 
  }
}
.icon-gear {
  &:before {
    content: $icon-gear; 
  }
}
.icon-trash-o {
  &:before {
    content: $icon-trash-o; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-file-o {
  &:before {
    content: $icon-file-o; 
  }
}
.icon-clock-o {
  &:before {
    content: $icon-clock-o; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-list-alt {
  &:before {
    content: $icon-list-alt; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-photo {
  &:before {
    content: $icon-photo; 
  }
}
.icon-picture-o {
  &:before {
    content: $icon-picture-o; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-pencil-square-o {
  &:before {
    content: $icon-pencil-square-o; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle; 
  }
}
.icon-times-circle {
  &:before {
    content: $icon-times-circle; 
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle; 
  }
}
.icon-question-circle {
  &:before {
    content: $icon-question-circle; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-times-circle-o {
  &:before {
    content: $icon-times-circle-o; 
  }
}
.icon-ban {
  &:before {
    content: $icon-ban; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-exclamation-triangle {
  &:before {
    content: $icon-exclamation-triangle; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-random {
  &:before {
    content: $icon-random; 
  }
}
.icon-comment {
  &:before {
    content: $icon-comment; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open; 
  }
}
.icon-bar-chart {
  &:before {
    content: $icon-bar-chart; 
  }
}
.icon-bar-chart-o {
  &:before {
    content: $icon-bar-chart-o; 
  }
}
.icon-twitter-square {
  &:before {
    content: $icon-twitter-square; 
  }
}
.icon-facebook-square {
  &:before {
    content: $icon-facebook-square; 
  }
}
.icon-cogs {
  &:before {
    content: $icon-cogs; 
  }
}
.icon-gears {
  &:before {
    content: $icon-gears; 
  }
}
.icon-comments {
  &:before {
    content: $icon-comments; 
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half; 
  }
}
.icon-heart-o {
  &:before {
    content: $icon-heart-o; 
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out; 
  }
}
.icon-linkedin-square {
  &:before {
    content: $icon-linkedin-square; 
  }
}
.icon-sign-in {
  &:before {
    content: $icon-sign-in; 
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-square-o {
  &:before {
    content: $icon-square-o; 
  }
}
.icon-bookmark-o {
  &:before {
    content: $icon-bookmark-o; 
  }
}
.icon-phone-square {
  &:before {
    content: $icon-phone-square; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-facebook-f {
  &:before {
    content: $icon-facebook-f; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-bullhorn {
  &:before {
    content: $icon-bullhorn; 
  }
}
.icon-bell-o {
  &:before {
    content: $icon-bell-o; 
  }
}
.icon-certificate {
  &:before {
    content: $icon-certificate; 
  }
}
.icon-arrow-circle-left {
  &:before {
    content: $icon-arrow-circle-left; 
  }
}
.icon-arrow-circle-right {
  &:before {
    content: $icon-arrow-circle-right; 
  }
}
.icon-arrow-circle-up {
  &:before {
    content: $icon-arrow-circle-up; 
  }
}
.icon-arrow-circle-down {
  &:before {
    content: $icon-arrow-circle-down; 
  }
}
.icon-globe {
  &:before {
    content: $icon-globe; 
  }
}
.icon-wrench {
  &:before {
    content: $icon-wrench; 
  }
}
.icon-group {
  &:before {
    content: $icon-group; 
  }
}
.icon-users {
  &:before {
    content: $icon-users; 
  }
}
.icon-chain {
  &:before {
    content: $icon-chain; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud; 
  }
}
.icon-paperclip {
  &:before {
    content: $icon-paperclip; 
  }
}
.icon-floppy-o {
  &:before {
    content: $icon-floppy-o; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-square {
  &:before {
    content: $icon-square; 
  }
}
.icon-bars {
  &:before {
    content: $icon-bars; 
  }
}
.icon-navicon {
  &:before {
    content: $icon-navicon; 
  }
}
.icon-reorder {
  &:before {
    content: $icon-reorder; 
  }
}
.icon-table {
  &:before {
    content: $icon-table; 
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-unsorted {
  &:before {
    content: $icon-unsorted; 
  }
}
.icon-sort-desc {
  &:before {
    content: $icon-sort-desc; 
  }
}
.icon-sort-down {
  &:before {
    content: $icon-sort-down; 
  }
}
.icon-sort-asc {
  &:before {
    content: $icon-sort-asc; 
  }
}
.icon-sort-up {
  &:before {
    content: $icon-sort-up; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-rotate-left {
  &:before {
    content: $icon-rotate-left; 
  }
}
.icon-undo {
  &:before {
    content: $icon-undo; 
  }
}
.icon-comment-o {
  &:before {
    content: $icon-comment-o; 
  }
}
.icon-exchange {
  &:before {
    content: $icon-exchange; 
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; 
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-file-text-o {
  &:before {
    content: $icon-file-text-o; 
  }
}
.icon-plus-square {
  &:before {
    content: $icon-plus-square; 
  }
}
.icon-circle-o {
  &:before {
    content: $icon-circle-o; 
  }
}
.icon-spinner {
  &:before {
    content: $icon-spinner; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-folder-o {
  &:before {
    content: $icon-folder-o; 
  }
}
.icon-folder-open-o {
  &:before {
    content: $icon-folder-open-o; 
  }
}
.icon-smile-o {
  &:before {
    content: $icon-smile-o; 
  }
}
.icon-frown-o {
  &:before {
    content: $icon-frown-o; 
  }
}
.icon-meh-o {
  &:before {
    content: $icon-meh-o; 
  }
}
.icon-flag-o {
  &:before {
    content: $icon-flag-o; 
  }
}
.icon-star-half-empty {
  &:before {
    content: $icon-star-half-empty; 
  }
}
.icon-star-half-full {
  &:before {
    content: $icon-star-half-full; 
  }
}
.icon-star-half-o {
  &:before {
    content: $icon-star-half-o; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-exclamation {
  &:before {
    content: $icon-exclamation; 
  }
}
.icon-calendar-o {
  &:before {
    content: $icon-calendar-o; 
  }
}
.icon-rocket {
  &:before {
    content: $icon-rocket; 
  }
}
.icon-arrow-circle-left2 {
  &:before {
    content: $icon-arrow-circle-left2; 
  }
}
.icon-arrow-circle-right2 {
  &:before {
    content: $icon-arrow-circle-right2; 
  }
}
.icon-arrow-circle-up2 {
  &:before {
    content: $icon-arrow-circle-up2; 
  }
}
.icon-arrow-circle-down2 {
  &:before {
    content: $icon-arrow-circle-down2; 
  }
}
.icon-unlock-alt {
  &:before {
    content: $icon-unlock-alt; 
  }
}
.icon-play-circle {
  &:before {
    content: $icon-play-circle; 
  }
}
.icon-minus-square {
  &:before {
    content: $icon-minus-square; 
  }
}
.icon-minus-square-o {
  &:before {
    content: $icon-minus-square-o; 
  }
}
.icon-pencil-square {
  &:before {
    content: $icon-pencil-square; 
  }
}
.icon-external-link-square {
  &:before {
    content: $icon-external-link-square; 
  }
}
.icon-share-square {
  &:before {
    content: $icon-share-square; 
  }
}
.icon-caret-square-o-down {
  &:before {
    content: $icon-caret-square-o-down; 
  }
}
.icon-toggle-down {
  &:before {
    content: $icon-toggle-down; 
  }
}
.icon-caret-square-o-up {
  &:before {
    content: $icon-caret-square-o-up; 
  }
}
.icon-toggle-up {
  &:before {
    content: $icon-toggle-up; 
  }
}
.icon-caret-square-o-right {
  &:before {
    content: $icon-caret-square-o-right; 
  }
}
.icon-toggle-right {
  &:before {
    content: $icon-toggle-right; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-file-text {
  &:before {
    content: $icon-file-text; 
  }
}
.icon-youtube-square {
  &:before {
    content: $icon-youtube-square; 
  }
}
.icon-youtube-play {
  &:before {
    content: $icon-youtube-play; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-long-arrow-down {
  &:before {
    content: $icon-long-arrow-down; 
  }
}
.icon-long-arrow-up {
  &:before {
    content: $icon-long-arrow-up; 
  }
}
.icon-long-arrow-left {
  &:before {
    content: $icon-long-arrow-left; 
  }
}
.icon-long-arrow-right {
  &:before {
    content: $icon-long-arrow-right; 
  }
}
.icon-arrow-circle-o-right {
  &:before {
    content: $icon-arrow-circle-o-right; 
  }
}
.icon-arrow-circle-o-left {
  &:before {
    content: $icon-arrow-circle-o-left; 
  }
}
.icon-caret-square-o-left {
  &:before {
    content: $icon-caret-square-o-left; 
  }
}
.icon-toggle-left {
  &:before {
    content: $icon-toggle-left; 
  }
}
.icon-dot-circle-o {
  &:before {
    content: $icon-dot-circle-o; 
  }
}
.icon-plus-square-o {
  &:before {
    content: $icon-plus-square-o; 
  }
}
.icon-envelope-square {
  &:before {
    content: $icon-envelope-square; 
  }
}
.icon-graduation-cap {
  &:before {
    content: $icon-graduation-cap; 
  }
}
.icon-mortar-board {
  &:before {
    content: $icon-mortar-board; 
  }
}
.icon-building {
  &:before {
    content: $icon-building; 
  }
}
.icon-file-pdf-o {
  &:before {
    content: $icon-file-pdf-o; 
  }
}
.icon-file-word-o {
  &:before {
    content: $icon-file-word-o; 
  }
}
.icon-file-excel-o {
  &:before {
    content: $icon-file-excel-o; 
  }
}
.icon-file-powerpoint-o {
  &:before {
    content: $icon-file-powerpoint-o; 
  }
}
.icon-file-image-o {
  &:before {
    content: $icon-file-image-o; 
  }
}
.icon-file-photo-o {
  &:before {
    content: $icon-file-photo-o; 
  }
}
.icon-file-picture-o {
  &:before {
    content: $icon-file-picture-o; 
  }
}
.icon-file-archive-o {
  &:before {
    content: $icon-file-archive-o; 
  }
}
.icon-file-zip-o {
  &:before {
    content: $icon-file-zip-o; 
  }
}
.icon-file-audio-o {
  &:before {
    content: $icon-file-audio-o; 
  }
}
.icon-file-sound-o {
  &:before {
    content: $icon-file-sound-o; 
  }
}
.icon-file-movie-o {
  &:before {
    content: $icon-file-movie-o; 
  }
}
.icon-file-video-o {
  &:before {
    content: $icon-file-video-o; 
  }
}
.icon-file-code-o {
  &:before {
    content: $icon-file-code-o; 
  }
}
.icon-life-bouy {
  &:before {
    content: $icon-life-bouy; 
  }
}
.icon-life-buoy {
  &:before {
    content: $icon-life-buoy; 
  }
}
.icon-life-ring {
  &:before {
    content: $icon-life-ring; 
  }
}
.icon-life-saver {
  &:before {
    content: $icon-life-saver; 
  }
}
.icon-support {
  &:before {
    content: $icon-support; 
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-paper-plane-o {
  &:before {
    content: $icon-paper-plane-o; 
  }
}
.icon-send-o {
  &:before {
    content: $icon-send-o; 
  }
}
.icon-circle-thin {
  &:before {
    content: $icon-circle-thin; 
  }
}
.icon-sliders {
  &:before {
    content: $icon-sliders; 
  }
}
.icon-newspaper-o {
  &:before {
    content: $icon-newspaper-o; 
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-at {
  &:before {
    content: $icon-at; 
  }
}
.icon-area-chart {
  &:before {
    content: $icon-area-chart; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-line-chart {
  &:before {
    content: $icon-line-chart; 
  }
}
.icon-toggle-off {
  &:before {
    content: $icon-toggle-off; 
  }
}
.icon-toggle-on {
  &:before {
    content: $icon-toggle-on; 
  }
}
.icon-genderless {
  &:before {
    content: $icon-genderless; 
  }
}
.icon-facebook-official {
  &:before {
    content: $icon-facebook-official; 
  }
}
.icon-pinterest-p {
  &:before {
    content: $icon-pinterest-p; 
  }
}
.icon-user-plus {
  &:before {
    content: $icon-user-plus; 
  }
}
.icon-user-times {
  &:before {
    content: $icon-user-times; 
  }
}
.icon-calendar-plus-o {
  &:before {
    content: $icon-calendar-plus-o; 
  }
}
.icon-calendar-minus-o {
  &:before {
    content: $icon-calendar-minus-o; 
  }
}
.icon-calendar-times-o {
  &:before {
    content: $icon-calendar-times-o; 
  }
}
.icon-calendar-check-o {
  &:before {
    content: $icon-calendar-check-o; 
  }
}
.icon-article {
  &:before {
    content: $icon-article; 
  }
}
.icon-course {
  &:before {
    content: $icon-course; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-login {
  &:before {
    content: $icon-login; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-options {
  &:before {
    content: $icon-options; 
  }
}
.icon-resource {
  &:before {
    content: $icon-resource; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-study-support {
  &:before {
    content: $icon-study-support; 
  }
}
.icon-wellbeing {
  &:before {
    content: $icon-wellbeing; 
  }
}
.icon-webinar {
  &:before {
    content: $icon-webinar; 
  }
}
.icon-resource_digital-text {
  &:before {
    content: $icon-resource_digital-text; 
  }
}
.icon-resource-discission {
  &:before {
    content: $icon-resource-discission; 
  }
}
.icon-resource_digital-interactive {
  &:before {
    content: $icon-resource_digital-interactive; 
  }
}
.icon-resource_physical-text {
  &:before {
    content: $icon-resource_physical-text; 
  }
}
.icon-resource-velocity-text {
  &:before {
    content: $icon-resource-velocity-text; 
  }
}
.icon-resource-fm-text {
  &:before {
    content: $icon-resource-fm-text; 
  }
}
.icon-resource-cgma-text {
  &:before {
    content: $icon-resource-cgma-text; 
  }
}
.icon-resource-connect-text {
  &:before {
    content: $icon-resource-connect-text; 
  }
}
.icon-resource-default-text {
  &:before {
    content: $icon-resource-default-text; 
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch; 
  }
}
.icon-badge {
  &:before {
    content: $icon-badge; 
  }
}
.icon-grid {
  &:before {
    content: $icon-grid; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-date {
  &:before {
    content: $icon-date; 
  }
}
.icon-schedule {
  &:before {
    content: $icon-schedule; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}

