


.card {
  background: $color-white;
  border: 1px solid transparent;
  color: inherit;
  display: block;
  text-decoration: none;
  width: 100%;

  &--kaplan {
    background: tint($color-brand-primary, 90%);
    color: $color-brand-primary;
  }

  &--muted {
    background: $color-grey-lightest;
  }

  &--link {
    border-radius: $border-radius;

    &:hover,
    &:focus {
      border-color: $color-grey-light;
      cursor: pointer;
    }

    &:focus {
      outline-width: thin;
    }

    &:hover {
      box-shadow: 0 0 .25em 0 $color-darken;
    }

    &:active {
      box-shadow: none;
    }
  }

  &--inset {
    padding: $spacing;
  }

  &--border {
    border-color: $border-color;
  }

  &--flex {
    display: flex;
  }
}
