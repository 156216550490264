

.button {
  appearance: none;
  background-color: $button-bg;
  border-color: transparent;
  border-radius: $border-radius;
  border-style: $border-style;
  border-width: $button-border-width;
  color: $button-color;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: $font-size-base;
  font-weight: 600;
  line-height: $button-line-height;
  padding: $spacing-sm $spacing;
  text-align: center;
  text-decoration: none;
  transition: $transition-timing $transition-duration;
  transition-property: background-color, color;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  will-change: background-color, color;

  & + & {
    margin-left: $spacing;
  }

  &--primary,
  &--default {
    background-color: $color-brand-purple;

    &:hover,
    &:focus {
      background-color: shade($color-brand-purple, 10%);
    }
  }

  &--secondary {
    background-color: $color-brand-secondary-green;

    &:hover,
    &:focus {
      background-color: $color-brand-primary-green;
    }
  }

  &--outline {
    background: transparent;
    border: 2px solid currentColor;
    color: $color-brand-primary;
    transition: color $transition-duration $transition-timing;

    &:hover,
    &:focus {
      color: shade($color-brand-purple, 10%);
    }
  }

  &--link {
    background: transparent;
    color: $link-color;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
    text-decoration: underline;

    &:hover {
      color: $link-hover-color;
    }

    &:focus {
      color: $link-focus-color;
    }

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  &--white,
  &--white:hover {
    background: $color-white;
    border-color: $color-white;
    color: $color-brand-primary;
  }

  &--outline-white {
    background: transparent;
    border-color: $color-white;
    color: $color-white;
  }

  &--white,
  &--outline-white {
    transition: opacity $transition-duration $transition-timing;

    &:hover {
      opacity: .875;
    }
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--ghost {
    background: transparent;
    color: $link-color;
    font-weight: normal;
    margin: (-$spacing-sm) (-$spacing-sm);
    padding: $spacing-sm $spacing-sm;

    &:hover,
    &:focus {
      background-color: $color-darken;
      color: $link-hover-color;
    }

    &:active {
      background-color: $color-darken-more;
      color: $link-active-color;
    }

  }

  &--notes {
    background: $color-note;
    color: $body-color;

    &:hover,
    &:focus {
      background-color: tint($color-note, 10%);
    }
  }

  &--small {
    padding-bottom: $spacing-xs;
    padding-top: $spacing-xs;
  }

  + .button {
    margin-left: $spacing-sm;
  }

  &:disabled,
  &.disabled,
  &[disabled] {
    background-color: $color-grey-lighter;
    color: $color-brand-white;
    cursor: not-allowed;
    opacity: 1;
    pointer-events: none;
  }
}
