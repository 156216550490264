

.page {
  background: $color-white;
  min-height: 60vh;
  padding: $spacing-fluid $spacing-fluid;
  position: relative;

  &__full {
    margin: 0 (-$spacing-fluid * 1/.90);
  }

  @media only screen and (min-width: map-get($bp, desktop)) {

    &__inner {
      display: flex;
      flex-wrap: wrap;
      margin: 0 (-$spacing-root / 2);

      .page--full-width & {
        display: block;
        margin: 0;
      }
    }

    &__main,
    &__aside {
      padding: 0 ($spacing-root / 2);
    }

    &__main {
      margin-bottom: $spacing-lg;
      width: 66.66%;

      .page--full-width & {
        width: 100%;
      }
    }

    &__aside {
      width: 33.33%;

      .page--full-width & {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: map-get($bp, max)) {
    &__inner {
      display: flex;
      margin: 0 (-$spacing-xl / 2);
    }

    &__main,
    &__aside {
      padding: 0 ($spacing-xl / 2);
    }
  }

  &__footer {
    border-top: $border-width solid $border-color;
    flex: 1 1 auto;
    margin: 0 (-$spacing-fluid * 1/.9) (-$spacing-fluid * 1/.9);
    padding: $spacing $spacing-fluid;
    min-width: 100%;
  }
}
