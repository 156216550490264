

.guide {
  background: $color-white;
  min-height: 60vh;
  position: relative;

  &__title {
    margin-bottom: $spacing;
    padding: $spacing 0 0;

    // Themes
    @include themeBackground;
  }

  &__header {
    padding: $spacing-fluid $spacing-fluid 0;

    .syllabus-code {
      margin-bottom: $spacing-sm;
    }
  }

  &__main {
    padding: $spacing-fluid $spacing-fluid;
  }

  @media screen and (min-width: map-get($bp, desktop)) {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding: 0 ($spacing-root / 2);

    &__alert {
      width: 100%;

      .alert {
        padding: $spacing $spacing;
      }
    }

    &__header,
    &__main {
      padding: $spacing-fluid-sm ($spacing-root / 2);
    }

    &__header {
      width: 33.33%;
    }

    &__main {
      margin-top: $spacing;
      width: 66.66%;
    }

    // &__title {
    //   padding: 0 0 0 $spacing-fluid;
    // }

    &--logged-in {
      margin-top: $spacing-lg;
    }
  }

  @media screen and (min-width: map-get($bp, max)) {
    padding: 0 ($spacing * 2.5);

    &__header {
      padding: $spacing-fluid-sm $spacing 0 0;
      padding-left: 0;
    }

    &__header,
    &__main {
      padding: $spacing-fluid-sm $spacing;
    }
  }

}
