// Most styles set in the React for this one
// As it's a react port of jQuery knob

.dial {
  position: relative;

  &__value,
  &__text {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition-duration: $transition-duration;
    transition-property: transform, opacity;
    transition-timing-function: $transition-timing;
  }

  &__text {
    opacity: 0;
    transform: translate(-50%, 50%);
  }

  &:hover {
    .dial__text {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    .dial__value {
      opacity: 0;
      transform: translate(-50%, -100%);
    }
  }

  &--no-hover {
    .dial__text {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    .dial__value {
      opacity: 0;
    }
  }

  &--text-first {
    .dial__text {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    .dial__value {
      opacity: 0;
      transform: translate(-50%, 50%);
    }

    &:hover {
      .dial__value {
        opacity: 1;
        transform: translate(-50%, -50%);
      }

      .dial__text {
        opacity: 0;
        transform: translate(-50%, -100%);
      }
    }
  }
}
