fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $spacing-sm;
  padding: 0;

  small {
    font-weight: 400;
  }
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: $spacing-xs;
}

input,
select,
textarea {
  background-color: $input-bg;
  border: $border-width-sm $border-style $input-border-color;
  border-radius: $border-radius;
  box-shadow: $input-box-shadow;
  color: $input-color;
  display: block;
  font-size: 1rem;
  line-height: $line-height;
  padding: $spacing-sm $spacing-sm;
  text-overflow: ellipsis;
  transition: border-color $transition-timing $transition-duration;
  width: 100%;

  &:hover {
    border-color: $input-border-color-hover;
  }

  &:focus {
    border-color: $input-border-color-focus;
    outline: none;
  }

  &:disabled {
    background-color: $input-bg-disabled;
    cursor: not-allowed;
    user-select: none;

    &:hover {
      border: $border-width-sm $border-style $input-border-color;
    }
  }

  &::placeholder {
    color: $input-color-placeholder;
  }
}

select {
  height: calc(#{$spacing-sm * 2 + $line-height * 1em} + #{$border-width-sm * 2});
}

textarea {
  resize: vertical;
}

/* clears the 'X' from Internet Explorer */
[type=search]::-ms-clear,
[type=search]::-ms-reveal {
  display: none;
  height: 0;
  width: 0;
}

/* clears the 'X' from Chrome */
[type="search"]::-webkit-search-decoration,
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-results-button,
[type="search"]::-webkit-search-results-decoration {
  display: none;
}

[type='checkbox'],
[type='radio'] {
  appearance: normal;
  display: inline;
  margin-right: $spacing-xs;
  width: auto;
}
