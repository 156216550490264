.password {
  &__wrap {
    position: relative;
  }

  &__button {
    background: none;
    border: 0 none;
    color: $color-grey;
    cursor: pointer;
    padding: $input-padding-vertical $input-padding-horizontal;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .icon {
      display: block;
    }
  }

  // Hide password buttons in IE/Edge, we provide a custom show/hide btn
  &__input::-ms-reveal,
  &__input::-ms-clear {
    display: none;
  }
}
