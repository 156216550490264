

.level-summary {
  border-bottom: $border-width solid $border-color;
  padding-bottom: $spacing-fluid;

  li:last-child & {
    border-bottom: 0 none;
    padding-bottom: 0;
  }

  &__header {

  }

  &__content {
    padding-top: $spacing;
  }

  &__title {
    color: $color-brand-primary;
  }

  &__status {
    color: $body-color;
    display: inline-block;
    font-size: $font-size-xs;
    font-weight: 400;
    min-width: 10em;
    white-space: nowrap;

    &--success {
      color: $color-success;
    }
  }

  &__action {
    // float: right;
    font-size: $font-size-sm;
    text-decoration: none;
  }

  @media only screen and (min-width: map-get($bp, 'xxxl')) {
    display: flex;

    &__header {
      padding-right: $spacing-lg;
      width: 35%;
    }

    &__content {
      width: 65%;
    }

  }
}
