

$enter-transition-duration: .5s;
$enter-transition-timing: ease-in-out;

@keyframes enter-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes enter-fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -1em, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes enter-fade-in-up {
  0% {
    opacity: 0;
    transform: translate(0, 1em);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes enter-boing {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}



.enter {
  animation-duration: $enter-transition-duration;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: $enter-transition-timing;
  min-width: 100%;
  position: relative;
  z-index: 1;

  &--fade {
    opacity: 0;

    &.enter--enter {
      animation-name: enter-fade;
    }
  }

  &--fade-in-down {
    opacity: 0;

    &.enter--enter {
      animation-name: enter-fade-in-down;
    }
  }

  &--fade-in-up {
    opacity: 0;

    &.enter--enter {
      animation-name: enter-fade-in-up;
    }
  }

  &--boing {
    opacity: 0;

    &.enter--enter {
      animation-name: enter-boing;
      animation-timing-function: $transition-timing-boing;
    }
  }

  &--flex {
    display: flex;
  }
}
