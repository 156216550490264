.new {
  background: $gradient-green;
  border-radius: 3px;
  color: $color-white;
  display: none;
  font-size: .75em;
  margin-left: $spacing-sm;
  padding: $spacing-xs / 4 $spacing-xs;
  text-transform: uppercase;
  vertical-align: middle;

  @media screen and (min-width: 22.5em) {
    display: inline-block;
  }
}
