.organisation-summary {
  &__image {
    margin: 0 auto $spacing-sm;
    width: 200px;

    @media screen and (min-width: map-get($bp, tablet)) {
      width: 128px;
    }
  }

  &__img {
    display: block;
    object-fit: contain;
  }

  &__content {
    p:last-child {
      margin: 0;
    }
  }

  @media screen and (min-width: map-get($bp, md)) {
    align-items: center;
    display: flex;

    &__image {
      flex-shrink: 0;
      height: auto;
      margin: 0 $spacing 0 0;
      max-width: 8em;
    }

    &__content {
      flex: 1 1 auto;
    }
  }

  &--with-image {
    .organisation-summary__title {
      display: none;
    }
  }
}
