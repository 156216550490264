

.note-input {
  background: $color-note;
  border: 0 none;
  border-radius: 0;
  line-height: $line-height;
  max-height: 100%;
  min-height: 12.5em;
  overflow: auto;
  padding: $spacing-fluid $spacing-fluid;
  resize: none;
  text-indent: 0;
}
