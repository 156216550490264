
.progress-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: (-$spacing-xs) (-$spacing-xs);

  &__item {
    margin: $spacing-xs $spacing-xs;
  }

  &--even {
    margin: auto;

    .progress-nav__item {
      flex: 1 1 45%;

      @media screen and (min-width: map-get($bp, sm)) {
        flex: 1;
      }

      @media screen and (min-width: map-get($bp, md)) {
        flex: 1 1 45%;
      }

      @media screen and (min-width: map-get($bp, desktop)) {
        flex: 1;
      }
    }
  }

  &--right {
    @media screen and (min-width: map-get($bp, tablet)) {
      justify-content: flex-end;
    }
  }
}
