
@mixin lead {
  font-size: $font-size-lg;
  font-weight: $heading-font-weight;

  @media screen and (min-width: map-get($bp, tablet)) {
    font-size: $h3-font-size;
  }
}


.lead {
  @include lead;
}
