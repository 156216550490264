


$blue: $color-blue;
$pink: tint($color-brand-purple, 10%);
$purple: $color-brand-purple;

$grey-light: $color-brand-grey-light;
$grey: $color-brand-grey;
$grey-dark: $color-brand-grey-dark;

$background: $color-darken;
$border: $color-darken-more;

$text: inherit;
$text-code: $pink;
$text-strong: inherit;
$text-light: $grey-light;
$text-headings: inherit;
$text-blockquote: $purple;

$link: $purple;
$link-hover: $purple;
$link-visited: $grey;

$background-code: $background;
$background-ins: lime;
$background-mark: yellow;

$table-background: hsl(0, 0%, 100%);
$table-background-even: hsl(0, 0%, 98%);
$table-background-hover: hsl(0, 0%, 96%);
$table-border: $border;
$table-thead: $grey-dark;

// Spacing
$margin: $spacing;

// Typography
$family-wysiwyg: $font-family-sans-serif;
$family-code: $font-family-monospace;

$weight-strong: 700;
$weight-headings: 400;

// Options
$closer-lists: false !default;
$custom-colors: false !default;
$custom-fonts: false !default;
$font-smoothing: false !default;
$heading-borders: false !default;
$show-underline: true !default;
$table-hover: false !default;
$table-striped: false !default;
$visited-links: true !default;

// Mixins
@mixin cell($font-size) {
  font-size: #{$font-size}em;
  padding: (($margin / 2) / $font-size);
}

@mixin heading($font-size, $with-border: false, $top-space: false) {
  font-size: #{$font-size}em;
  margin-bottom: ($margin / $font-size) * .25;
  @if $top-space {
    margin-top: ($margin * 2 / $font-size);
  } @else {
    margin-top: ($margin / $font-size);
  }
  @if $heading-borders and $with-border {
    border-bottom: 1px solid $border;
    padding-bottom: ($margin / $font-size);
  }
}

// Styles
.wysiwyg {
  line-height: $line-height;

  @if $custom-colors {
    color: $text
  }
  @if $custom-fonts {
    font-family: $family-wysiwyg;
  }
  @if $font-smoothing {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  // Inlines
  a {
    text-decoration: none;
    @if $custom-colors {
      color: $link;
      @if $visited-links {
        &:visited {
          color: $link-visited;
        }
      }
    }
    @if $show-underline {
      border-bottom: 1px solid;
    } @else {
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }

  abbr {
    border-bottom: 1px dotted;
    cursor: help;
  }

  cite {
    font-style: italic;
  }

  hr {
    background: $border;
    border: none;
    display: block;
    height: 1px;
    margin-bottom: $margin;
    margin-top: $margin;
  }

  img {
    vertical-align: text-bottom;
  }

  ins {
    background-color: $background-ins;
    text-decoration: none;
  }

  mark {
    background-color: $background-mark;
  }

  small {
    font-size: .875em;
  }

  strong {
    font-weight: $weight-strong;
    @if $custom-colors {
      color: $text-strong;
    }
  }

  sub,
  sup {
    font-size: .875em;
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: super;
  }

  // Blocks
  p,
  dl,
  ol,
  ul,
  blockquote,
  table {
    margin-bottom: $margin;

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  p {
    &:empty {
      display: none;
    }

    &:last-child {
      margin: 0;
    }
  }
  // Headings
  h1, h2, h3, h4, h5, h6 {
    color: $purple;
    font-weight: $weight-headings;
    line-height: $heading-line-height;

    &:first-child {
      margin-top: 0;
    }

    @if $custom-colors {
      color: $text-headings;
    }
  }

  h1 {
    @include heading(1.75, true);
  }

  h2 {
    @include heading(1.5, true, true);
  }

  h3 {
    @include heading(1.25);
  }

  h4 {
    @include heading(1.125);
  }

  h5 {
    @include heading(1);
  }

  h6 {
    @include heading(.875);
  }

  // Paragraphs
  @if $closer-lists {
    p {
      & + dl,
      & + ol,
      & + ul {
        margin-top: -$margin;
      }
    }
  }

  // Lists
  dd {
    margin-left: $margin;
  }

  ol,
  ul {
    list-style-position: outside;
    margin-left: $margin * 1.125;
    margin-top: $margin;
  }

  li {
    margin-bottom: $margin;

    p:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;

      ol {
        list-style-type: lower-roman;

        ol {
          list-style-type: lower-greek;

          ol {
            list-style-type: decimal;

            ol {
              list-style-type: lower-alpha;
            }
          }
        }
      }
    }
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;

      ul {
        list-style-type: square;

        ul {
          list-style-type: circle;

          ul {
            list-style-type: disc;

            ul {
              list-style-type: circle;
            }
          }
        }
      }
    }
  }
  // Blockquote
  blockquote {
    background: $color-grey-lightest;
    border-left: .25em solid $color-orange-dark;
    margin: $margin 0;
    padding: $margin;

    p {
      font-style: italic;
      margin-bottom: 0;
    }
  }

  // Code
  code,
  kbd,
  samp {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    background-color: $background-code;
    color: $text;
    font-size: 0.9em;
    @if $custom-fonts {
      font-family: $family-code;
    }
  }

  code,
  kbd,
  samp {
    border-radius: 3px;
    line-height: $line-height / 0.9;
    padding: 0.1em 0.4em 0.2em;
    vertical-align: baseline;
    @if $custom-colors {
      color: $text-code;
    }
  }


  code {
    background: none;
    font-size: 1em;
    line-height: 1em;
  }

  // Figure
  figure {
    margin-bottom: $margin * 2;
    text-align: center;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  figcaption {
    font-size: 0.8em;
    margin-top: $margin / 1.6;
    @if $custom-colors {
      color: $text-light;
    }
  }

  // Table
  table {
    width: 100%;
  }

  th,
  td {
    @include cell(1);
    border: 1px solid $table-border;
    line-height: 1.4;
  }

  thead,
  tfoot {
    tr {
      @if $table-striped {
        background-color: $table-border;
      }

      @else {
        background-color: $table-background-hover;
      }
    }

    th,
    td {
      @include cell(.9);
      @if $custom-colors {
        color: $table-thead;
      }

      code {
        background-color: $table-background;
      }
    }
  }

  tbody {
    tr {
      background-color: $table-background;
      @if $table-striped {
        &:nth-child(even) {
          background-color: $table-background-even;
        }

        @if $table-hover {
          &:hover {
            background-color: $table-background-hover;
          }
        }
      } @else {
        @if $table-hover {
          &:hover {
            background-color: $table-background-even;
          }
        }
      }
    }
  }

  &--with-lead {
    > p:first-child {
      @include lead;
    }
  }
}
