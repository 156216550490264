

.exam-summary {

  &__badge {
    display: inline-block;
    position: relative;

    &-icon {
      font-size: 2em;
    }

    &-text {
      color: $color-white;
      display: inline-block;
      left: 0;
      line-height: 2em;
      position: absolute;
      right: 0;
      text-align: center;
      transform: scale(.75);
    }
  }

  &__meta {
    list-style-type: none;
    margin: 0 0 $spacing;
    padding-left: 2.5em;

    &-item {
      margin-bottom: $spacing-sm;

      .icon {
        margin-right: $spacing-xs;
      }
    }
  }

  &__progress {
    margin-bottom: $spacing-sm;
  }

  &__forecast {
    font-size: $font-size-sm;
    margin: 0;
  }
}
