ul,
ol {
  margin: 0 0 $spacing $spacing;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
}

dt,
dd {
  margin: 0;
}
