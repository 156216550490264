

.session-number {
  $session-number-border-width: 2px;
  $session-number-border-width-small: $session-number-border-width / 2;
  $session-number-border-color: currentColor;
  $session-number-border: $session-number-border-width solid $session-number-border-color;
  $session-number-border-small: $session-number-border-width-small solid $session-number-border-color;

  border: $session-number-border;
  border-radius: 50%;
  color: inherit;
  display: inline-block;
  font-size: 2rem;
  height: $line-height * 1em;
  padding: .125em;
  position: relative;
  vertical-align: middle;
  width: $line-height * 1em;

  + .session-number {
    margin-left: $spacing-sm;
  }

  &__inner,
  &__check,
  &__check .icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__check {
    background: currentColor;
    border-radius: 50%;
    height: calc(100% + #{$session-number-border-width});
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
    transition: transform $transition-duration $transition-timing, opacity $transition-duration $transition-timing;
    width: calc(100% + #{$session-number-border-width});

    .icon {
      color: $color-white;
    }
  }

  // ------- Themes

  &--brand-primary {
    color: $color-brand-primary;
  }

  &--blue-dark {
    color: $color-blue-dark;
  }

  &--green-dark {
    color: $color-green-dark;
  }

  &--green {
    color: $color-green;
  }

  &--orange-dark {
    color: $color-orange-dark;
  }

  // ------- Sizes

  &--medium {
    font-size: 1.5em;
  }

  &--small {
    font-size: 1em;
  }

  &--extra-small {
    font-size: $font-size-sm;
  }

  &--small,
  &--extra-small {
    border: $session-number-border-small;

    .session-number__check {
      height: calc(100% + #{$session-number-border-width-small});
      width: calc(100% + #{$session-number-border-width-small});
    }
  }

  &--completed {
    .session-number__check {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
