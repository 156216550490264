

.highlight-box {
  background: $color-grey-lightest;
  min-height: 29em;
  padding: $spacing $spacing;

  @media screen and (min-width: map-get($bp, md)) {
    padding: $spacing-lg $spacing-lg;
  }

  &__icon {
    font-size: 3em;
    margin-bottom: $spacing-xs;
    opacity: .5;
  }

  &__title {
    margin-bottom: $spacing-sm;
  }

  &__content {
    > p:last-child {
      margin: 0;
    }
  }

  // Don't force height
  &--natural-height {
    min-height: 0;
  }

  // Themes

  &--brand-primary {
    background: $color-brand-primary;
    color: $color-white;
  }

  &--green-dark {
    background: $color-green-dark;
    color: $color-white;
  }

  &--blue {
    background: $color-blue;
    color: $color-white;

    * {
      border-color: $color-lighten-more;
      color: inherit;
    }
  }

  &--blue-light {
    background: lighten($color-blue, 40%);
    color: inherit;
  }

  &--orange-light {
    background: lighten($color-brand-primary-orange, 40%);
    color: inherit;
  }

  &--grey-light {
    background: $color-grey-lightest;
    color: inherit;
  }

  &--white {
    background: $color-white;
    color: inherit;
  }
}
