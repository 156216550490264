



.subject-code {
  $folder-border: .125em;
  $folder-tab-width: 40%;
  background: $color-grey;
  color: $color-grey;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 400;
  position: relative;
  transition: transform $transition-duration $transition-timing;
  vertical-align: middle;

  @media screen and (min-width: map-get($bp, 'sm')) {
    font-size: 2rem;
  }

  &::before {
    border: $folder-border * 2 solid transparent;
    border-bottom-color: currentColor;
    border-width: $folder-border * 2;
    content: '';
    height: 0;
    left: calc(#{$folder-tab-width} - #{$folder-border} * 2);
    position: absolute;
    top: 0;
    transform: translate(0, -75%);
    width: 0;
    z-index: 1;
  }

  &::after {
    background: currentColor;
    content: '';
    height: $folder-border * 2;
    left: 0;
    position: absolute;
    top: -$folder-border - .002em;
    width: $folder-tab-width;
    z-index: 1;
  }

  + .folder {
    margin-left: $spacing;
  }

  &__inner {
    display: block;
  }

  &__check {
    background: $color-green-dark;
    border-radius: 50%;
    font-size: .5em;
    height: 1.75em;
    line-height: 1.875;
    position: absolute;
    right: -.75em;
    text-align: center;
    top: -.75em;
    width: 1.75em;

    &-icon {
      color: $color-white;
      line-height: 1;
      margin: 0;
    }
  }

  &__badge {
    line-height: 1;
    position: absolute;
    right: -.4em;
    top: -.4em;

    &-badge {
      color: currentColor;
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-check {
      color: $color-white;
      font-size: .5em;
      margin: .5em;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__focus {
    background: $color-orange;
    border-radius: 50%;
    font-size: .5em;
    height: 1.75em;
    line-height: 1.875;
    position: absolute;
    right: -.75em;
    text-align: center;
    top: -.75em;
    width: 1.75em;

    &-icon {
      color: $color-white;
      line-height: 1;
      margin: 0;
    }
  }

  &__inner {
    border-top: $folder-border solid $color-darken-more;
    color: $color-white;
    padding: 0 0 $folder-border;
  }

  &__text {
    display: inline-block;
    letter-spacing: -1px;
    line-height: 1.5;
    min-height: 1em;
    min-width: 2.1em;
    text-align: center;
    transform: scale(.875);
  }

  &__name {
    display: none;
  }

  // -------- Themes

  &--brand-primary {
    background: $color-brand-primary;
    color: $color-brand-primary;

    .subject-code__badge-badge {
      color: tint($color-brand-primary, 10%);
    }

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-brand-primary;
      }
    }
  }

  &--brand-grey {
    background: $color-brand-grey;
    color: $color-brand-grey;

    .subject-code__badge-badge {
      color: $color-brand-grey-light;
    }

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-brand-grey;
      }
    }
  }

  &--blue {
    background: $color-blue;
    color: $color-blue;

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-blue;
      }
    }
  }

  &--blue-dark {
    background: $color-blue-dark;
    color: $color-blue-dark;

    .subject-code__badge-badge {
      color: $color-blue;
    }

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-blue-dark;
      }
    }
  }

  &--green {
    background: $color-green;
    color: $color-green;

    .subject-code__badge-badge {
      color: $color-green-dark;
    }

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-green;
      }
    }
  }

  &--green-dark {
    background: $color-green-dark;
    color: $color-green-dark;

    .subject-code__badge-badge {
      color: $color-green;
    }

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-green-dark;
      }
    }

    .subject-code__check {
      background: $color-green;
    }
  }

  &--orange {
    background: $color-orange;
    color: $color-orange;

    .subject-code__badge-badge {
      color: $color-orange-dark;
    }

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-orange;
      }
    }
  }

  &--orange-dark {
    background: $color-orange-dark;
    color: $color-orange-dark;

    .subject-code__badge-badge {
      color: $color-orange;
    }

    &-on-white {
      background: $color-white;

      .subject-code__inner {
        color: $color-orange-dark;
      }
    }
  }

  // ------- On white only

  &[class*='-on-white'] {
    background: $color-white;

    &::before {
      border-bottom-color: $color-white;
    }

    &::after {
      background: $color-white;
    }

    .subject-code__inner {
      border-top-color: $color-white;
    }
  }

  // ------- Sizes

  &--medium {
    font-size: 1.5em;
  }

  &--small {
    font-size: 1em;

    .subject-code__inner {
      padding: 0;
    }

    .subject-code__text {
      min-width: 2.25em;
    }
  }

  &--extra-small {
    font-size: 1em;

    .subject-code__inner {
      border-top: 0 none;
      padding: 0;
    }

    .subject-code__text {
      min-width: 2.25em;
    }
  }

  // ------- With name

  &--with-name {
    @media screen and (min-width: map-get($bp, 'lg')) {
      max-width: 5em;
      text-align: left;
      width: 100%;

      &::before,
      &::after {
        font-size: 2em;
      }

      .subject-code__inner {
        border-top-width: $folder-border * 2;
        padding: $spacing-xs $spacing-sm $folder-border;
      }

      .subject-code__text {
        display: block;
        font-size: $font-size-base * 1.5;
        font-weight: bold;
        text-align: left;
        transform: scale(1);
      }

      .subject-code__name {
        display: block;
        font-size: $font-size-base;
        font-weight: 400;
        line-height: 1.25;
        min-height: (4em * 1.25) + $spacing-sm + $spacing-sm;
        padding-bottom: $spacing-sm;
      }
    }
  }

  // -------- Loading

  &--loading {
    .subject-code__text,
    .subject-code__name {
      @include loading;

      &::before {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
}
