.choice {
  display: grid;
  gap: $spacing-sm;
  grid-template-columns: 1fr 1fr;

  input {
    @include sr-only;
  }

  &__item {
    background: $input-bg;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    color: $input-color;
    cursor: pointer;
    padding: $input-padding-vertical $input-padding-horizontal;

    &:hover {
      border-color: $input-border-color-hover;
    }

    &:focus {
      border-color: $input-border-color-focus;
    }

    &-content {
      align-items: center;
      display: grid;
      gap: $spacing-sm;
      grid-template-columns: auto;
      grid-template-rows: auto;
      text-align: center;

      > * {
        grid-column: 1;
        grid-row: 1;
      }
    }
  }
}
