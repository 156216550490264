

.dashboard {
  overflow: hidden;

  @media screen and (min-width: map-get($bp, md)) {
    margin: $spacing $spacing;
  }

  @media screen and (min-width: map-get($bp, max)) {
    margin: 0 $spacing;
  }

  &__section {
    background: $color-white;
    width: 100%;

    &--inset {
      padding: $spacing $spacing;

      @media screen and (min-width: map-get($bp, desktop)) {
        padding: $spacing $spacing-lg;
      }
    }

    &--flex {
      display: flex;
    }

    &--info {
      background: $color-info-light;
    }

    &--warning {
      background: $color-warning-light;
    }

    &--success {
      background: $color-success-light;
    }

    &--danger {
      background: $color-danger-light;
    }

    &--muted {
      background: $color-grey-lightest;
    }
  }

  &__progress {
    margin-top: $spacing-sm;
    text-align: center;

    &-content {

    }

    &-label {
      font-size: $font-size-sm;
    }

    @media screen and (min-width: map-get($bp, lg)) {
      &-content {
        padding: 0 $spacing;
      }
    }

    &--passed {
      .dashboard__progress-label {
        color: $color-success;
      }
    }

    &--current {
      .dashboard__progress-label {
        color: $color-orange-dark;
      }
    }

    &--upcoming {
      .dashboard__progress-label {
        color: $color-grey-light;
      }
    }
  }

  &__next {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;

    &-header {
      padding: $spacing $spacing 0;

      @media screen and (min-width: map-get($bp, desktop)) {
        padding: $spacing $spacing-lg 0;
      }

      // @TODO should probably be a button
      &-action {
        border-radius: $border-radius;
        display: inline-block;
        margin: (-$spacing-xs) (-$spacing-sm);
        padding: $spacing-xs $spacing-sm;

        @media screen and (min-width: map-get($bp, desktop)) {
          margin: (-$spacing-xs) (-$spacing);
        }

        &:hover,
        &:focus {
          background: $color-darken;
          color: $link-hover-color;
        }

        &:active {
          color: $link-active-color;
        }

        &--active {
          background: $color-brand-primary;
          color: $color-white;
        }
      }
    }

    &-content {
      flex: 1 0 auto;
      padding: $spacing $spacing 0;

      &-link {
        border-top: $border-width $border-style $border-color;
        color: inherit;
        display: block;
        margin:0 (-$spacing);
        padding: $spacing $spacing;

        &:hover,
        &:focus {
          background-color: #f6f6f6;
        }
      }

      @media screen and (min-width: map-get($bp, desktop)) {
        padding: $spacing $spacing-lg 0;

        &-link {
          margin:0 (-$spacing-lg);
          padding: $spacing $spacing-lg;
        }
      }
    }

    &-footer {
      background: $color-grey-lightest;
      border-top: $border-width $border-style $border-color;
      padding: $spacing $spacing;

      @media screen and (min-width: map-get($bp, desktop)) {
        padding: $spacing $spacing-lg;
      }
    }
  }
}
