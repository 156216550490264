table {
  border-collapse: if($table-border-collapse, collapse, separate);
  margin: $spacing-sm 0;
  table-layout: if($table-layout-fixed, fixed, auto);
  width: 100%;
}

th {
  font-weight: 600;
  padding: $spacing-sm 0;
  text-align: left;
}

td {
  border-bottom: $border;
  padding: $spacing-sm 0;
}

tr,
td,
th {
  vertical-align: middle;
}
