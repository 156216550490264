

.progress-update-check {
  border: $border-width $border-style $border-color;
  display: table;
  padding: $spacing-sm $spacing-sm;
  table-layout: fixed;
  width: 100%;

  &:hover {
    color: $link-color;
    cursor: pointer;
  }

  + .progress-update-check {
    border-top-width:0;
  }

  &__meta {
    display: table-cell;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__title {
    font-weight: normal;
    vertical-align: middle;
  }

  &__paper {
    margin-left: 1px; // Fix overflow issue
    margin-right: $spacing-sm;
  }

  &__action {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    white-space: nowrap;
    width: 10ch;
  }

  .check__label {
    font-size: $font-size-xs;
  }

  &--disabled,
  &--disabled:hover {
    color: inherit;
    cursor: default;
  }
}
