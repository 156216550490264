

.spacing {
  &--top {
    &-xs {
      margin-top: $spacing-xs;
    }

    &-sm {
      margin-top: $spacing-sm;
    }

    &-md {
      margin-top: $spacing;
    }

    &-lg {
      margin-top: $spacing-lg;
    }

    &-xl {
      margin-top: $spacing-xl;
    }
  }

  &--left {
    &-xs {
      margin-left: $spacing-xs;
    }

    &-sm {
      margin-left: $spacing-sm;
    }

    &-md {
      margin-left: $spacing;
    }

    &-lg {
      margin-left: $spacing-lg;
    }

    &-xl {
      margin-left: $spacing-xl;
    }

    &-fluid {
      margin-left: $spacing-fluid;
    }
  }

  &--bottom {
    &-xs {
      margin-bottom: $spacing-xs;
    }

    &-sm {
      margin-bottom: $spacing-sm;
    }

    &-md {
      margin-bottom: $spacing;
    }

    &-lg {
      margin-bottom: $spacing-lg;
    }

    &-xl {
      margin-bottom: $spacing-xl;
    }
  }

  &--right {
    &-xs {
      margin-right: $spacing-xs;
    }

    &-sm {
      margin-right: $spacing-sm;
    }

    &-md {
      margin-right: $spacing;
    }

    &-lg {
      margin-right: $spacing-lg;
    }

    &-xl {
      margin-right: $spacing-xl;
    }

    &-fluid {
      margin-right: $spacing-fluid;
    }
  }
}
